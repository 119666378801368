import { useEffect } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import memoize from 'lodash/memoize';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { TimelineItemType } from '../models';

const localInitialState = {
    moveTimelines: [],
    isLoading: true,
    error: null,
    didInitialLoad: false
};

export const moveTimelineQueryFragment = `
    fragment MoveTimelineFields on MoveTimeline {
        id
        dateTime
        timelineItemType
        notes
        isLeftSide
        leftSideStrength
        isRightSide
        rightSideStrength
        perceivedProgress
        moveId
        sessionId
        instructorId
        _version
        _deleted
    }
`;

const getMoveTimelinesByOwnerQuery = `
    ${moveTimelineQueryFragment}
    query QueryMoveTimelinesByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: moveTimelinesByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MoveTimelineFields
                move {
                    name,
                    discipline
                }
                session {
                    name
                }
                instructor {
                    name,
                    _deleted
                }
            }
            nextToken
        }
    }
`;

const getSaveMoveTimelineQuery = ({ isUpdate }) => `
    ${moveTimelineQueryFragment}
    mutation SaveMoveTimeline
    (
        $dateTime: String!
        $timelineItemType: TimelineItemType
        $notes: String
        $isLeftSide: Boolean
        $leftSideStrength: PracticedSideStrength
        $isRightSide: Boolean
        $rightSideStrength: PracticedSideStrength
        $perceivedProgress: Int
        $moveId: ID
        $sessionId: ID
        $instructorId: ID
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateMoveTimeline' : 'createMoveTimeline'}(
            input: {
                dateTime: $dateTime
                timelineItemType: $timelineItemType
                notes: $notes
                isLeftSide: $isLeftSide
                leftSideStrength: $leftSideStrength
                isRightSide: $isRightSide
                rightSideStrength: $rightSideStrength
                perceivedProgress: $perceivedProgress
                moveId: $moveId
                sessionId: $sessionId
                instructorId: $instructorId
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...MoveTimelineFields
            move {
                name,
                discipline
            }
            session {
                name
            }
            instructor {
                name
            }
        }
    }
`;

const deleteMoveTimelineQuery = `
    ${moveTimelineQueryFragment}
    mutation DeleteMoveTimeline
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteMoveTimeline(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...MoveTimelineFields
        }
    }
`;

const moveTimelineGearFieldsQueryFragment = `
    fragment MoveTimelineGearFields on MoveTimelineGear {
        id
        moveTimelineID
        timelineGearID
        _version
        _deleted
    }
`;

const listMoveTimelineGearQuery = `
    ${moveTimelineGearFieldsQueryFragment}
    query ListMoveTimelineGears
    (
        $limit: Int
        $nextToken: String
    ) {
        result: listMoveTimelineGears(
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MoveTimelineGearFields
                timelineGear {
                    name
                }
            }
            nextToken
        }
    }
`;

const getSaveMoveTimelineGearQuery = ({ isUpdate }) => `
    ${moveTimelineGearFieldsQueryFragment}
    mutation SaveMoveTimelineGear
    (
        $moveTimelineID: ID!
        $timelineGearID: ID!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateMoveTimelineGear' : 'createMoveTimelineGear'}(
            input: {
                moveTimelineID: $moveTimelineID
                timelineGearID: $timelineGearID
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...MoveTimelineGearFields
            timelineGear {
                name
            }
        }
    }
`;

const deleteMoveTimelineGearQuery = `
    ${moveTimelineGearFieldsQueryFragment}
    mutation DeleteMoveTimelineGear
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteMoveTimelineGear(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...MoveTimelineGearFields
        }
    }
`;

const selectMoveTimelines = memoize(
    (moveTimelines, moveId, sessionId) => moveId ? filter(
        moveTimelines,
        { moveId }
    ) : sessionId ? filter(
        moveTimelines,
        { sessionId }
    ) : moveTimelines,
    (...args) => JSON.stringify(args)
);

const orderMoveTimelinesByDateTime = memoize(
    moveTimelines => orderBy(
        moveTimelines,
        [({ dateTime }) => new Date(dateTime)],
        ['desc']
    ),
    (...args) => JSON.stringify(args)
);

const orderMoveTimelineGear = gear => sortBy(
    gear,
    ['timelineGear.name']
);

const clearSelectors = () => {
    selectMoveTimelines.cache.clear();
    orderMoveTimelinesByDateTime.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isMoveTimelinesRetrieved()) {
        return;
    }

    getCache().setMoveTimelinesIsRetrieved();
    const moveTimelines = await getCache().getMoveTimelines();
    clearSelectors();
    setState({ moveTimelines });
};

const setMoveTimelines = moveTimelines => ({ setState }) => {
    clearSelectors();
    setState({ moveTimelines });
    getCache().setMoveTimelines(moveTimelines);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const fetchMoveTimelines = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading, didInitialLoad } = getState();
    if (didInitialLoad && isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const moveTimelines = [];
    const fetchMoveTimelinesData = async ({ nextToken }) => {
        const result = await graphql({
            query: getMoveTimelinesByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        moveTimelines.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchMoveTimelinesData({ nextToken });
        }
    };

    const moveTimelineGear = [];
    const fetchMoveTimelineGearData = async ({ nextToken }) => {
        const result = await graphql({
            query: listMoveTimelineGearQuery,
            variables: {
                limit: 100,
                nextToken
            }
        });

        moveTimelineGear.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchMoveTimelineGearData({ nextToken });
        }
    };

    try {
        dispatch(setIsLoading(true));
        dispatch(setError(null));

        await Promise.all([
            fetchMoveTimelinesData({ nextToken: null }),
            fetchMoveTimelineGearData({ nextToken: null })
        ]);

        dispatch(setIsLoading(false));
        dispatch(
            setMoveTimelines(
                map(
                    moveTimelines,
                    ({ instructorId, instructor, ...moveTimeline }) => ({
                        ...moveTimeline,
                        timelineItemType: moveTimeline.timelineItemType ?? TimelineItemType.MOVE,
                        gear: orderMoveTimelineGear(
                            filter(
                                moveTimelineGear,
                                { moveTimelineID: moveTimeline.id }
                            )
                        ),
                        instructorId: (!instructor || instructor._deleted) ? null : instructorId,
                        instructor: (!instructor || instructor._deleted) ? null : { ...instructor }
                    })
                )
            )
        );
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const saveMoveTimeline = ({
    moveTimeline: {
        gear,
        ...moveTimeline
    },
    moveTimelinePreviousState
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!moveTimelinePreviousState;
    const savedMoveTimeline = (await graphql({
        query: getSaveMoveTimelineQuery({ isUpdate }),
        variables: { ...moveTimeline }
    })).data.result;

    const newGear = [];
    await Promise.all([
        // delete removed gear
        ...(isUpdate ? map(
            moveTimelinePreviousState.gear,
            async ({ id, _version }) => {
                const isDeleted = !find(gear, { id });
                if (!isDeleted) {
                    return;
                }

                try {
                    await graphql({
                        query: deleteMoveTimelineGearQuery,
                        variables: { id, _version }
                    });
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        ) : []),
        // update / add gear
        ...map(
            gear,
            async gear => {
                if (gear.id) {
                    newGear.push(gear);
                    return;
                }

                try {
                    gear.moveTimelineID = savedMoveTimeline.id;
                    const savedGear = (await graphql({
                        query: getSaveMoveTimelineGearQuery({ isUpdate: false }),
                        variables: { ...gear }
                    })).data.result;
                    newGear.push(savedGear);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        )
    ]);

    savedMoveTimeline.gear = orderMoveTimelineGear(newGear);

    const moveTimelines = [...getState().moveTimelines];
    isUpdate && remove(moveTimelines, ({ id }) => id === savedMoveTimeline.id);
    moveTimelines.push(savedMoveTimeline);
    dispatch(setMoveTimelines(moveTimelines));
    return savedMoveTimeline;
};

const deleteMoveTimeline = ({
    moveTimeline: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedMoveTimeline = (await graphql({
        query: deleteMoveTimelineQuery,
        variables: { id, _version }
    })).data.result;

    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ id }) => id === deletedMoveTimeline.id);
    dispatch(setMoveTimelines(moveTimelines));
    return deletedMoveTimeline;
};

const onMoveSaved = ({ id, name, discipline }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    forEach(moveTimelines, moveTimeline => {
        if (moveTimeline.moveId === id) {
            moveTimeline.move.name = name;
            moveTimeline.move.discipline = discipline;
        }
    });

    dispatch(setMoveTimelines(moveTimelines));
};

const onSessionSaved = ({ id, name }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    forEach(moveTimelines, moveTimeline => {
        if (moveTimeline.sessionId === id) {
            moveTimeline.session.name = name;
        }
    });

    dispatch(setMoveTimelines(moveTimelines));
};

const onTimelineInstructorSaved = ({ id, name }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    forEach(moveTimelines, moveTimeline => {
        if (moveTimeline.instructorId === id) {
            moveTimeline.instructor.name = name;
        }
    });

    dispatch(setMoveTimelines(moveTimelines));
};

const onMoveTimelineGearSaved = ({ id, name }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    forEach(moveTimelines, moveTimeline => {
        const gear = find(moveTimeline.gear, { id });
        if (gear) {
            gear.name = name;
        }
    });

    dispatch(setMoveTimelines(moveTimelines));
};

const onMoveDeleted = ({ id }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ moveId }) => moveId === id);
    dispatch(setMoveTimelines(moveTimelines));
};

const onSessionDeleted = ({ id }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ sessionId }) => sessionId === id);
    dispatch(setMoveTimelines(moveTimelines));
};

const onTimelineInstructorDeleted = ({ id }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    let isChanged = false;
    forEach(moveTimelines, moveTimeline => {
        if (moveTimeline.instructorId === id) {
            isChanged = true;
            moveTimeline.instructorId = null;
            moveTimeline.instructor = null;
        }
    });
    isChanged && dispatch(setMoveTimelines(moveTimelines));
};

const onMoveTimelineGearDeleted = ({ id }) => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ gear }) => !!find(gear, { id }));
    dispatch(setMoveTimelines(moveTimelines));
};

const onAllMovesDeleted = () => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ timelineItemType }) => timelineItemType === TimelineItemType.MOVE);
    dispatch(setMoveTimelines(moveTimelines));
};

const onAllSessionsDeleted = () => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    remove(moveTimelines, ({ timelineItemType }) => timelineItemType === TimelineItemType.SESSION);
    dispatch(setMoveTimelines(moveTimelines));
};

const onAllTimelineInstructorsDeleted = () => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    dispatch(
        setMoveTimelines(
            map(
                moveTimelines,
                moveTimeline => ({
                    ...moveTimeline,
                    instructorId: null,
                    instructor: null
                })
            )
        )
    );
};

const onAllMoveTimelineGearsDeleted = () => ({ getState, dispatch }) => {
    const moveTimelines = [...getState().moveTimelines];
    dispatch(
        setMoveTimelines(
            map(
                moveTimelines,
                moveTimeline => ({
                    ...moveTimeline,
                    gear: []
                })
            )
        )
    );
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchMoveTimelines,
        onInitialLoad,
        onMoveSaved,
        onMoveDeleted,
        onAllMovesDeleted,
        onMoveTimelineGearSaved,
        onMoveTimelineGearDeleted,
        onAllMoveTimelineGearsDeleted,
        onSessionSaved,
        onSessionDeleted,
        onAllSessionsDeleted,
        onTimelineInstructorSaved,
        onTimelineInstructorDeleted,
        onAllTimelineInstructorsDeleted,
        saveMoveTimeline,
        deleteMoveTimeline
    },
    name: 'MoveTimelines'
});

const useMoveTimelinesState = createStateHook(
    Store,
    {
        selector: (
            {
                moveTimelines,
                isLoading,
                error,
                didInitialLoad
            },
            { moveId, sessionId }
        ) => ({
            moveTimelines: orderMoveTimelinesByDateTime(
                selectMoveTimelines(
                    moveTimelines,
                    moveId,
                    sessionId
                )
            ),
            isLoading,
            error,
            didInitialLoad
        })
    }
);

export const useMoveTimelinesActions = createActionsHook(Store);

export const useMoveTimelines = ({
    moveId,
    sessionId,
    canFetch
} = {}) => {
    const { fetchMoveTimelines, onInitialLoad } = useMoveTimelinesActions();
    const {
        moveTimelines,
        isLoading,
        error,
        didInitialLoad
    } = useMoveTimelinesState({ moveId, sessionId });

    useEffect(() => {
        if (canFetch === false) {
            return;
        }

        if (!didInitialLoad) {
            fetchMoveTimelines();
            onInitialLoad();
        }
    }, [
        didInitialLoad,
        fetchMoveTimelines,
        onInitialLoad,
        canFetch
    ]);

    return {
        moveTimelines,
        isLoading,
        error
    };
};

export const useFetchMoveTimelines = () => {
    const { fetchMoveTimelines } = useMoveTimelinesActions();
    return fetchMoveTimelines;
};

export const useSaveMoveTimeline = () => {
    const { saveMoveTimeline } = useMoveTimelinesActions();
    return saveMoveTimeline;
};

export const useDeleteMoveTimeline = () => {
    const { deleteMoveTimeline } = useMoveTimelinesActions();
    return deleteMoveTimeline;
};
