export const ScreenName = {
    home: 'home',
    termsAndConditions: 'terms_and_conditions',
    privacyPolicy: 'privacy_policy',
    onlineLibraryMoves: 'online_library_moves',
    onlineLibraryMovesMove: 'online_library_moves_move',
    onlineLibraryMove: 'online_library_move',
    externalContentPolicy: 'external_content_policy',
    contentSharingPolicy: 'content_sharing_policy',
    requestDeleteAccount: 'request_delete_account',
    onlineLibraryGuide: 'online_library_guide',
    contact: 'contact',
    goPlayStore: 'go_play_store',
    goAppStore: 'go_app_store',
    goInsta: 'go_insta',
    goFb: 'go_fb',
    goTikTok: 'go_tiktok',
    goReddit: 'go_reddit',
    goYouTube: 'go_youtube',
    goThreads: 'go_threads',
    goNotFound: 'go_not_found'
};
