import React from 'react';
import {
    Router,
    RouteComponent,
    createBrowserHistory
} from 'react-resource-router';
import { useAppRoutes } from '@pole-journal-web/app-routes';
import { WebAuthModal } from '@codexporer.io/web-auth-screens';
import { AppThemeProvider } from '@pole-journal-web/app-theme';

export const App = () => {
    const appRoutes = useAppRoutes();

    return (
        <AppThemeProvider>
            <Router routes={appRoutes} history={createBrowserHistory()}>
                <RouteComponent />
            </Router>
            <WebAuthModal />
        </AppThemeProvider>
    );
};
