import React from 'react';
import Helmet from 'react-helmet';
import uniq from 'lodash/uniq';

export const noCache = [
    {
        'http-equiv': 'Cache-Control',
        content: 'no-cache, no-store, must-revalidate'
    },
    {
        'http-equiv': 'Pragma',
        content: 'no-cache'
    },
    {
        'http-equiv': 'Expires',
        content: '0'
    }
];

export const Seo = ({
    description = 'Acrobacy is a digital journal for pole dance, aerial and circus skills training. It offers easy ways to catalogue moves, organize training notes and media, log workouts, and visualize progress.',
    lang = 'en',
    meta = [],
    keywords = [],
    title,
    image
}) => {
    const metaKeywords = uniq([
        ...(['pole dance', 'aerial acrobatics', 'aerials', 'pole fitness', 'dance', 'fitness', 'sports', 'aerial hoop', 'aerial silks', 'circus skills', 'acrobatics', 'aerial journal', 'pole journal']),
        ...keywords
    ]);

    const author = 'codexplorer.io';

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate='%s | Acrobacy'
            meta={[
                {
                    name: 'description',
                    content: description
                },
                ...(
                    metaKeywords.length > 0 ?
                        [{
                            name: 'keywords',
                            content: metaKeywords.join(', ')
                        }] :
                        []
                ),
                {
                    property: 'author',
                    content: author
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: description
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                ...(
                    image ?
                        [{
                            property: 'og:image',
                            content: image
                        }] :
                        []
                ),
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: description
                },
                ...meta
            ]}
        />
    );
};
