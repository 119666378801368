import React from 'react';
import { di } from 'react-magnetic-di';
import { AppPage } from '@pole-journal-web/app-page';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ContentContainer } from './styled';

export const Skeleton = () => {
    di(AppPage, ContentContainer, Spinner);

    return (
        <AppPage>
            <ContentContainer>
                <Spinner size={SpinnerSize.large} label='Loading...' />
            </ContentContainer>
        </AppPage>
    );
};
