export const EventName = {
    appStart: 'app_start',
    userAccountLoaded: 'user_account_loaded',
    openLink: 'open_link',
    shareMoveUrl: 'share_move_url',
    copyMoveUrl: 'copy_move_url',
    playStoreButtonClicked: 'play_store_button_clicked',
    appStoreButtonClicked: 'app_store_button_clicked',
    appGalleryButtonClicked: 'app_gallery_button_clicked',
    galaxyStoreButtonClicked: 'galaxy_store_button_clicked',
    submitContactButtonClicked: 'submit_contact_button_clicked',
    backToOnlineLibraryMoves: 'back_to_online_library_moves',
    signOut: 'sign_out',
    signInViewShown: 'sign_in_view_shown',
    signInUsername: 'sign_in_username',
    signInGoogle: 'sign_in_google',
    signInApple: 'sign_in_apple',
    resetPasswordViewShown: 'reset_password_view_shown',
    sendCode: 'send_code',
    resetPassword: 'reset_password',
    signUpViewShown: 'sign_up_view_shown',
    signUpUsername: 'sign_up_username',
    signUpGoogle: 'sign_up_google',
    signUpApple: 'sign_up_apple',
    verifyEmailViewShown: 'verify_email_view_shown',
    verifyEmail: 'verify_email'
};
