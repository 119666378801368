import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import { usePrevious } from '@codexporer.io/react-hooks';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import { graphql } from './graphql';
import { awsConfig } from '../aws.config';

const localInitialState = {
    movesData: {
        moves: [],
        isLoading: false,
        error: null,
        nextToken: null
    },
    moveDetailsData: {}
};

const getOnlineLibraryMoveFieldsQueryFragment = ({ shouldIncludeDetails }) => `
    fragment OnlineLibraryMoveFields on OnlineLibraryMove {
        id
        name
        altNames
        difficulty
        discipline
        coverImageId
        ${shouldIncludeDetails ? `
            coverVideoId
            coverVideoUrl
            links {
                link
                displayText
            }
            description
        ` : ''}
        _version
        _deleted
    }
`;

const getOnlineLibraryMovesByIndexSortedByNameQuery = `
    ${getOnlineLibraryMoveFieldsQueryFragment({ shouldIncludeDetails: false })}
    query QueryOnlineLibraryMovesSortedByName(
        $limit: Int
        $nextToken: String
    ) {
        result: onlineLibraryMovesSortedByName(
            indexSortedByName: 1
            sortDirection: ASC
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...OnlineLibraryMoveFields
            }
            nextToken
        }
    }
`;

export const getOnlineLibraryMoveByIdQuery = `
    ${getOnlineLibraryMoveFieldsQueryFragment({ shouldIncludeDetails: true })}
    query GetOnlineLibraryMove($id: ID!) {
        result: getOnlineLibraryMove(id: $id) {
            ...OnlineLibraryMoveFields
        }
    }
`;

const onlineLibraryMoveContributorFieldsFragment = `
    fragment OnlineLibraryMoveContributorFields on OnlineLibraryMoveContributor {
        userId
        user {
            username
        }
        _version
        _deleted
    }
`;

export const getOnlineLibraryMoveContributorByMoveQuery = `
    ${onlineLibraryMoveContributorFieldsFragment}
    query MoveContributorsByMove(
        $moveId: ID!
        $limit: Int
        $nextToken: String
    ) {
        result: moveContributorsByMove(
            moveId: $moveId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...OnlineLibraryMoveContributorFields
            }
            nextToken
        }
    }
`;

const setMoves = moves => ({ getState, setState }) => {
    setState({
        movesData: {
            ...getState().movesData,
            moves
        }
    });
};

const setNextToken = nextToken => ({ getState, setState }) => {
    setState({
        movesData: {
            ...getState().movesData,
            nextToken
        }
    });
};

const setIsLoadingMoves = isLoading => ({ getState, setState }) => {
    setState({
        movesData: {
            ...getState().movesData,
            isLoading
        }
    });
};

const setMovesError = error => ({ getState, setState }) => {
    setState({
        movesData: {
            ...getState().movesData,
            error
        }
    });
};

const resetMovesState = () => ({ setState }) => {
    setState({ movesData: localInitialState.movesData });
};

const setMoveDetails = move => ({ getState, setState }) => {
    setState({
        moveDetailsData: {
            ...getState().moveDetailsData,
            [move.id]: {
                ...(getState().moveDetailsData[move.id] ?? {}),
                move
            }
        }
    });
};

const setIsLoadingMoveDetails = ({
    id,
    isLoading
}) => ({ getState, setState }) => {
    setState({
        moveDetailsData: {
            ...getState().moveDetailsData,
            [id]: {
                ...(getState().moveDetailsData[id] ?? {}),
                isLoading
            }
        }
    });
};

const setMoveDetailsError = ({
    id,
    error
}) => ({ getState, setState }) => {
    setState({
        moveDetailsData: {
            ...getState().moveDetailsData,
            [id]: {
                ...(getState().moveDetailsData[id] ?? {}),
                error
            }
        }
    });
};

const resetMoveDetailsState = ({ id }) => ({ getState, setState }) => {
    setState({
        moveDetailsData: {
            ...getState().moveDetailsData,
            [id]: undefined
        }
    });
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const LIMIT = 20;
const fetchData = async ({
    nextToken,
    limit = LIMIT,
    moves = []
}) => {
    const result = await graphql({
        query: getOnlineLibraryMovesByIndexSortedByNameQuery,
        variables: {
            nextToken,
            limit
        }
    });

    moves.push(
        ...filter(
            result.data.result?.items,
            ({ _deleted }) => _deleted !== true
        )
    );

    nextToken = result.data.result?.nextToken;
    if (nextToken && moves.length < LIMIT) {
        return fetchData({
            nextToken,
            limit,
            moves
        });
    }

    return {
        moves,
        nextToken
    };
};

const fetchOnlineLibraryMoves = () => async ({ getState, dispatch }) => {
    const { isLoading } = getState().movesData;
    if (isLoading) {
        return;
    }

    try {
        dispatch(resetMovesState());
        dispatch(setIsLoadingMoves(true));

        const {
            moves,
            nextToken
        } = await fetchData({ nextToken: null });

        dispatch(setIsLoadingMoves(false));
        dispatch(setMoves(moves));
        dispatch(setNextToken(nextToken));
    } catch (error) {
        dispatch(setIsLoadingMoves(false));
        dispatch(setMovesError(error));
    }
};

const fetchMoreOnlineLibraryMoves = () => async ({ getState, dispatch }) => {
    const { isLoading, nextToken: previousNextToken } = getState().movesData;
    if (isLoading || !previousNextToken) {
        return;
    }

    try {
        dispatch(setIsLoadingMoves(true));

        const {
            moves,
            nextToken
        } = await fetchData({ nextToken: previousNextToken });

        dispatch(setIsLoadingMoves(false));
        dispatch(setMoves(
            reduce(
                moves,
                (moves, move) => {
                    !find(moves, { id: move.id }) && moves.push(move);
                    return moves;
                },
                [...getState().movesData.moves]
            )
        ));
        dispatch(setNextToken(nextToken));
    } catch (error) {
        dispatch(setIsLoadingMoves(false));
        dispatch(setMovesError(error));
    }
};

const fetchOnlineLibraryMove = ({
    id,
    shouldFetchContributors = false,
    authMode
}) => async ({ getState, dispatch }) => {
    const isLoading = getState().moveDetailsData[id]?.isLoading ?? false;
    if (isLoading) {
        return;
    }

    const previousContributors = getState().moveDetailsData[id]?.move?.contributors;
    try {
        dispatch(resetMoveDetailsState({ id }));
        dispatch(setIsLoadingMoveDetails({ id, isLoading: true }));

        const fetchContributors = async ({
            contributors = [],
            nextToken
        }) => {
            const result = await graphql({
                query: getOnlineLibraryMoveContributorByMoveQuery,
                variables: {
                    moveId: id,
                    limit: 100,
                    nextToken
                }
            });

            contributors.push(
                ...filter(
                    result.data.result?.items,
                    ({ _deleted }) => _deleted !== true
                )
            );

            nextToken = result.data.result?.nextToken;
            if (nextToken) {
                await fetchContributors({
                    contributors,
                    nextToken
                });
            }

            return contributors;
        };

        const [
            moveDetails,
            contributors
        ] = await Promise.all([
            graphql({
                query: getOnlineLibraryMoveByIdQuery,
                variables: { id },
                authToken: authMode === 'apiKey' ?
                    awsConfig.awsmobile.aws_appsync_apiKey :
                    undefined,
                authMode
            }),
            shouldFetchContributors ?
                fetchContributors({ nextToken: null }) :
                Promise.resolve()
        ]);

        dispatch(setIsLoadingMoveDetails({ id, isLoading: false }));
        dispatch(setMoveDetails({
            ...moveDetails.data.result,
            contributors: shouldFetchContributors ?
                sortBy(contributors, [({ user }) => user.username]) :
                previousContributors
        }));
    } catch (error) {
        dispatch(setIsLoadingMoveDetails({ id, isLoading: false }));
        dispatch(setMoveDetailsError({ id, error }));
        previousContributors && dispatch(setMoveDetails({
            contributors: previousContributors
        }));
    }
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchOnlineLibraryMoves,
        fetchMoreOnlineLibraryMoves,
        fetchOnlineLibraryMove
    },
    name: 'OnlineLibraryMoves'
});

const useOnlineLibraryMovesState = createStateHook(
    Store,
    {
        selector: ({
            movesData: {
                moves,
                isLoading,
                error,
                nextToken
            }
        }) => ({
            moves,
            isLoading: isLoading ?? true,
            error,
            nextToken
        })
    }
);

const useOnlineLibraryMoveState = createStateHook(
    Store,
    {
        selector: ({ moveDetailsData }, { id }) => {
            const error = moveDetailsData[id]?.error ?? null;
            return {
                move: !error ? (moveDetailsData[id]?.move ?? null) : null,
                isLoading: moveDetailsData[id]?.isLoading ?? true,
                error
            };
        }
    }
);

export const useOnlineLibraryMovesActions = createActionsHook(Store);

export const useOnlineLibraryMoves = ({ shouldFetchOnMount = false } = {}) => {
    const { fetchOnlineLibraryMoves } = useOnlineLibraryMovesActions();
    const {
        moves,
        isLoading,
        error,
        nextToken
    } = useOnlineLibraryMovesState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (shouldFetchOnMount && !didFetch) {
            setDidFetch(true);
            fetchOnlineLibraryMoves();
        }
    }, [
        shouldFetchOnMount,
        didFetch,
        fetchOnlineLibraryMoves
    ]);

    return {
        moves,
        isLoading,
        error,
        canLoadMore: !!nextToken
    };
};

export const useFetchOnlineLibraryMoves =
    () => useOnlineLibraryMovesActions().fetchOnlineLibraryMoves;

export const useFetchMoreOnlineLibraryMoves =
    () => useOnlineLibraryMovesActions().fetchMoreOnlineLibraryMoves;

export const useOnlineLibraryMove = ({
    id,
    shouldFetchOnMount = true,
    shouldFetchContributors = false,
    authMode = undefined
}) => {
    const { fetchOnlineLibraryMove } = useOnlineLibraryMovesActions();
    const {
        move,
        isLoading,
        error
    } = useOnlineLibraryMoveState({ id });
    const [didFetch, setDidFetch] = useState(false);
    const previousId = usePrevious(id);

    useEffect(() => {
        if (id && previousId && id !== previousId) {
            setDidFetch(false);
        }
    }, [id, previousId]);

    useEffect(() => {
        if (id && shouldFetchOnMount && !didFetch) {
            setDidFetch(true);
            fetchOnlineLibraryMove({
                id,
                shouldFetchContributors,
                authMode
            });
        }
    }, [
        shouldFetchOnMount,
        didFetch,
        fetchOnlineLibraryMove,
        id,
        shouldFetchContributors,
        authMode
    ]);

    return id ? {
        move,
        isLoading,
        error
    } : {
        isLoading: false,
        error: null,
        move: null
    };
};

export const useFetchOnlineLibraryMove =
    () => useOnlineLibraryMovesActions().fetchOnlineLibraryMove;
