import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';
import memoize from 'lodash/memoize';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { TimelineGear } from '../models';

const localInitialState = {
    gear: [],
    isLoading: null,
    error: null,
    didInitialLoad: false
};

const moveTimelineGearFieldsQueryFragment = `
    fragment MoveTimelineGearFields on TimelineGear {
        id
        name
        _version
        _deleted
    }
`;

const getMoveTimelineGearByOwnerQuery = `
    ${moveTimelineGearFieldsQueryFragment}
    query QueryMoveTimelineGearByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: timelineGearByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MoveTimelineGearFields
            }
            nextToken
        }
    }
`;

const getSaveMoveTimelineGearQuery = ({ isUpdate }) => `
    ${moveTimelineGearFieldsQueryFragment}
    mutation TimelineGear
    (
        $name: String!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateTimelineGear' : 'createTimelineGear'}(
            input: {
                name: $name
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...MoveTimelineGearFields
        }
    }
`;

const deleteMoveTimelineGearQuery = `
    ${moveTimelineGearFieldsQueryFragment}
    mutation DeleteTimelineGear
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteTimelineGear(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...MoveTimelineGearFields
        }
    }
`;

const orderMoveTimelineGear = memoize(
    gear => sortBy(
        gear,
        ['name']
    ),
    (...args) => JSON.stringify(args)
);

const clearSelectors = () => {
    orderMoveTimelineGear.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isMoveTimelineGearRetrieved()) {
        return;
    }

    getCache().setMoveTimelineGearIsRetrieved();
    const gear = await getCache().getMoveTimelineGear();
    clearSelectors();
    setState({ gear });
};

const setMoveTimelineGear = gear => ({ setState }) => {
    clearSelectors();
    setState({ gear });
    getCache().setMoveTimelineGear(gear);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchMoveTimelineGear = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const gear = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getMoveTimelineGearByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        gear.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setMoveTimelineGear(gear));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveMoveTimelineGear = ({
    gear,
    gearPreviousState
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!gearPreviousState;
    const savedGear = (await graphql({
        query: getSaveMoveTimelineGearQuery({ isUpdate }),
        variables: { ...gear }
    })).data.result;

    const gearItems = [...getState().gear];
    if (isUpdate) {
        remove(gearItems, ({ id }) => id === savedGear.id);
        const previousName = gearPreviousState.name;
        const { name } = savedGear;
        previousName !== name && dispatch(actions.getLinkedStore('moveTimelines')).onMoveTimelineGearSaved(savedGear);
    }

    gearItems.push(savedGear);
    dispatch(setMoveTimelineGear(gearItems));
    return savedGear;
};

const deleteMoveTimelineGear = ({
    gear: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedGear = (await graphql({
        query: deleteMoveTimelineGearQuery,
        variables: { id, _version }
    })).data.result;

    const gearItems = [...getState().gear];
    remove(gearItems, ({ id }) => id === deletedGear.id);
    dispatch(actions.getLinkedStore('moveTimelines')).onMoveTimelineGearDeleted(deletedGear);
    dispatch(setMoveTimelineGear(gearItems));
    return deletedGear;
};

const deleteAllMoveTimelineGear = () => async ({ dispatch }) => {
    await DataStore.delete(TimelineGear, Predicates.ALL);
    dispatch(actions.getLinkedStore('moveTimelines')).onAllMoveTimelineGearsDeleted();
    dispatch(setMoveTimelineGear([]));
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchMoveTimelineGear,
        onInitialLoad,
        saveMoveTimelineGear,
        deleteMoveTimelineGear,
        deleteAllMoveTimelineGear
    },
    name: 'MoveTimelineGear'
});

const useMoveTimelineGearState = createStateHook(
    Store,
    {
        selector: (
            {
                gear,
                isLoading,
                error,
                didInitialLoad
            }
        ) => ({
            gear: orderMoveTimelineGear(gear),
            isLoading: isLoading || isLoading === null,
            error,
            didInitialLoad
        })
    }
);

export const useMoveTimelineGearActions = createActionsHook(Store);

export const useMoveTimelineGear = () => {
    const { fetchMoveTimelineGear, onInitialLoad } = useMoveTimelineGearActions();
    const {
        gear,
        isLoading,
        error,
        didInitialLoad
    } = useMoveTimelineGearState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchMoveTimelineGear();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchMoveTimelineGear,
        onInitialLoad
    ]);

    return {
        gear,
        isLoading,
        error
    };
};

export const useFetchMoveTimelineGear = () => {
    const { fetchMoveTimelineGear } = useMoveTimelineGearActions();
    return fetchMoveTimelineGear;
};

export const useSaveMoveTimelineGear = () => {
    const { saveMoveTimelineGear } = useMoveTimelineGearActions();
    return saveMoveTimelineGear;
};

export const useDeleteMoveTimelineGear = () => {
    const { deleteMoveTimelineGear } = useMoveTimelineGearActions();
    return deleteMoveTimelineGear;
};

export const useDeleteAllMoveTimelineGear = () => {
    const { deleteAllMoveTimelineGear } = useMoveTimelineGearActions();
    return deleteAllMoveTimelineGear;
};
