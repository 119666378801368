import React, {
    useState,
    useEffect,
    useRef,
    useMemo
} from 'react';
import FontAwesome6 from 'react-native-vector-icons/dist/FontAwesome6';
import { useTheme } from '@pole-journal-web/app-theme';
import {
    Shimmer,
    ShimmerElementType
} from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { OS } from '@codexporer.io/expo-device';
import {
    instagramLink,
    facebookLink,
    redditLink,
    tikTokLink,
    youtubeLink,
    playStoreLink,
    appStoreLink,
    threadsLink
} from '@pole-journal/external-links';
import {
    useMe,
    useFetchMe,
    useClearDatastore
} from '@pole-journal/aws-amplify/src/datastore';
import {
    useAppAnalytics,
    EventName
} from '@pole-journal-web/app-analytics-bridge';
import { UserProfilePhoto } from '@pole-journal-web/user-profile-photo';
import {
    useAuthenticationState,
    useAuthenticationStateActions
} from '@codexporer.io/expo-amplify-auth';
import { useWebAuthModalActions } from '@codexporer.io/web-auth-screens';
import logo from './assets/logo.svg';
import {
    ScreenRoot,
    Screen,
    Header,
    Content,
    AppDownloadButtonsRoot,
    AppDownloadButtonsContent,
    FooterContainer,
    Footer,
    FooterRow,
    FooterColumn,
    NavLinks,
    NavLink,
    Copyright,
    FooterSeparator,
    Title,
    commonContentPositionStyle,
    HeaderBrand,
    HeaderLogo,
    HeaderText,
    HeaderProfileButtonWrapper,
    HeaderProfileButton,
    HeaderProfileSignIn,
    USER_AVATAR_SIZE
} from './styled';

const PLAY_STORE = 'Play Store';
const APP_STORE = 'App Store';

const AppDownloadButtons = () => {
    const { sendEvent } = useAppAnalytics();

    const link = OS.isAndroid() ? playStoreLink : appStoreLink;
    const store = OS.isAndroid() ? PLAY_STORE : APP_STORE;

    const handleStoreButtonClick = () => {
        sendEvent({
            name: OS.isAndroid() ?
                EventName.playStoreButtonClicked :
                EventName.appStoreButtonClicked
        });
    };

    const handlePlayStoreButtonClick = () => {
        sendEvent({ name: EventName.playStoreButtonClicked });
    };

    const handleAppStoreButtonClick = () => {
        sendEvent({ name: EventName.appStoreButtonClicked });
    };

    return (
        <AppDownloadButtonsRoot>
            <AppDownloadButtonsContent>
                For better experience download Acrobacy app from
                {' '}
                {
                    OS.isAndroid() || OS.isIOS() ? (
                        <a
                            href={link}
                            onClick={handleStoreButtonClick}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {store}
                        </a>
                    ) : (
                        <>
                            <a
                                href={playStoreLink}
                                onClick={handlePlayStoreButtonClick}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {PLAY_STORE}
                            </a>
                            {' or '}
                            <a
                                href={appStoreLink}
                                onClick={handleAppStoreButtonClick}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {APP_STORE}
                            </a>
                        </>
                    )
                }
            </AppDownloadButtonsContent>
        </AppDownloadButtonsRoot>
    );
};

export const AppPage = ({
    children,
    shouldDisplayAppDownload = false
}) => {
    const {
        me,
        error: meError
    } = useMe();
    const fetchMe = useFetchMe();
    const [{ isAuthenticated }] = useAuthenticationState();
    const [isContentScrolled, setIsContentScrolled] = useState(false);
    const { colors } = useTheme();
    const { sendEvent } = useAppAnalytics();
    const {
        show: showWebAuthModal,
        hide: hideWebAuthModal
    } = useWebAuthModalActions();

    const userAvatarRef = useRef(null);
    const [isUserMenuVisible, setIsUserMenuVisible] = React.useState(false);
    const onShowUserMenu = event => {
        event.preventDefault();
        setIsUserMenuVisible(true);
    };
    const onHideUserMenu = () => setIsUserMenuVisible(false);

    const clearDatastore = useClearDatastore();
    // cleardatastore on signout and signin
    const clearDatastoreRef = useRef();
    clearDatastoreRef.current = clearDatastore;

    const [, {
        signOut
    }] = useAuthenticationStateActions();

    useEffect(() => {
        if (isAuthenticated && !me && !meError) {
            fetchMe();
        }
    }, [
        fetchMe,
        isAuthenticated,
        me,
        meError
    ]);

    useEffect(() => {
        if (isAuthenticated) {
            hideWebAuthModal();
        }
    }, [
        isAuthenticated,
        hideWebAuthModal
    ]);

    useEffect(() => {
        me?.id && sendEvent({ name: EventName.userAccountLoaded });
    }, [
        me?.id,
        sendEvent
    ]);

    const handleContentScroll = event => {
        setIsContentScrolled(event.target.scrollTop > 5);
    };

    const userMenuItems = useMemo(() => isAuthenticated ? [
        {
            key: 'sign-out',
            text: 'Sign Out',
            onClick: async () => {
                sendEvent({ name: EventName.signOut });
                clearDatastoreRef.current();
                signOut();
            }
        }
    ] : [
        {
            key: 'sign-in',
            text: 'Sign In',
            onClick: () => {
                clearDatastoreRef.current();
                showWebAuthModal({
                    isModalDismissible: true
                });
            }
        }
    ], [
        isAuthenticated,
        sendEvent,
        signOut,
        showWebAuthModal
    ]);

    return (
        <ScreenRoot>
            <Screen>
                <Header hasBorderBottom={isContentScrolled}>
                    <HeaderBrand to='/'>
                        <HeaderLogo src={logo} />
                        <HeaderText>Acrobacy</HeaderText>
                    </HeaderBrand>
                    <HeaderProfileButtonWrapper ref={userAvatarRef}>
                        {!isAuthenticated && (
                            <HeaderProfileButton onClick={onShowUserMenu}>
                                <HeaderProfileSignIn>
                                    <FontAwesome6 name='user' solid size={USER_AVATAR_SIZE * 0.5} color={colors.primaryAlt} />
                                </HeaderProfileSignIn>
                            </HeaderProfileButton>
                        )}
                        {isAuthenticated && !me && (
                            <Shimmer
                                width={USER_AVATAR_SIZE}
                                shimmerElements={
                                    [{
                                        type: ShimmerElementType.circle,
                                        height: USER_AVATAR_SIZE
                                    }]
                                }
                                shimmerColors={{ background: colors.primary }}
                            />
                        )}
                        {isAuthenticated && me && (
                            <HeaderProfileButton onClick={onShowUserMenu}>
                                <UserProfilePhoto
                                    size={USER_AVATAR_SIZE}
                                    username={me.username}
                                    profileImageId={me.profileImageId}
                                />
                            </HeaderProfileButton>
                        )}
                    </HeaderProfileButtonWrapper>
                </Header>
                <ContextualMenu
                    items={userMenuItems}
                    hidden={!isUserMenuVisible}
                    target={userAvatarRef}
                    onItemClick={onHideUserMenu}
                    onDismiss={onHideUserMenu}
                />
                <Content onScroll={handleContentScroll}>
                    {children}
                    {shouldDisplayAppDownload && <AppDownloadButtons />}
                    <FooterContainer>
                        <Footer>
                            <FooterRow>
                                <FooterColumn>
                                    <Title>
                                        Acrobacy
                                    </Title>
                                    <FooterSeparator />
                                </FooterColumn>
                            </FooterRow>
                            <FooterRow isFlex>
                                <FooterColumn isFlex>
                                    <NavLink to='/#'>Home</NavLink>
                                    <br />
                                    <NavLink to='/online-library-moves'>Moves library</NavLink>
                                    <br />
                                    <NavLink to='/help-center'>Info hub</NavLink>
                                </FooterColumn>
                                <FooterColumn isFlex>
                                    <NavLink to='/terms-and-conditions'>Terms & conditions</NavLink>
                                    <br />
                                    <NavLink to='/privacy-policy'>Privacy policy</NavLink>
                                    <br />
                                    <NavLink to='/contact'>Contact us</NavLink>
                                </FooterColumn>
                            </FooterRow>
                            <FooterRow>
                                <FooterColumn>
                                    <br />
                                    <br />
                                    <NavLinks>
                                        <NavLink
                                            to={tikTokLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='tiktok' size={26} color={colors.primaryAlt} brand />
                                        </NavLink>
                                        &nbsp;
                                        &nbsp;
                                        <NavLink
                                            to={instagramLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='instagram' size={30} color={colors.primaryAlt} />
                                        </NavLink>
                                        &nbsp;
                                        &nbsp;
                                        <NavLink
                                            to={threadsLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='square-threads' size={30} color={colors.primaryAlt} brand />
                                        </NavLink>
                                        &nbsp;
                                        &nbsp;
                                        <NavLink
                                            to={facebookLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='facebook' size={27} color={colors.primaryAlt} />
                                        </NavLink>
                                        &nbsp;
                                        &nbsp;
                                        <NavLink
                                            to={redditLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='reddit' size={30} color={colors.primaryAlt} />
                                        </NavLink>
                                        &nbsp;
                                        &nbsp;
                                        <NavLink
                                            to={youtubeLink}
                                            target='_blank'
                                        >
                                            <FontAwesome6 name='youtube' size={30} color={colors.primaryAlt} />
                                        </NavLink>
                                    </NavLinks>
                                    <Copyright>
                                        Copyright © 2023 Acrobacy
                                    </Copyright>
                                </FooterColumn>
                            </FooterRow>
                        </Footer>
                    </FooterContainer>
                </Content>
            </Screen>
        </ScreenRoot>
    );
};

export { commonContentPositionStyle };
