import React, { useEffect, useRef } from 'react';
import { useMediaSource } from '@pole-journal-web/media-source';
import {
    StorageAccessLevel,
    StoreType
} from '@pole-journal/aws-amplify/src/storage';
import {
    AvatarRoot,
    AvatarLetter,
    ProfileImage
} from './styled';

export const COVER_PHOTO_WIDTH = 16;
export const COVER_PHOTO_HEIGHT = 9;

export const UserProfilePhoto = ({
    size,
    username,
    profileImageId,
    identityId = null
}) => {
    const {
        mediaSource,
        thumbnailSource,
        setMediaSource
    } = useMediaSource({
        id: profileImageId,
        accessLevel: StorageAccessLevel.protected,
        storeType: StoreType.userProfileImage,
        identityId
    });

    const imageChangedDependenciesRef = useRef(null);
    imageChangedDependenciesRef.current = { setMediaSource };
    useEffect(() => {
        const { setMediaSource } = imageChangedDependenciesRef.current;
        setMediaSource(null);
    }, [profileImageId]);

    return (
        <AvatarRoot
            size={size}
            hasBorder={!profileImageId}
        >
            {!profileImageId && (
                <AvatarLetter size={size}>
                    {username[0]}
                </AvatarLetter>
            )}
            {profileImageId && (
                <ProfileImage
                    shouldDisplaySkeleton
                    shouldBlurThumbnail
                    key={profileImageId}
                    thumbnailSource={thumbnailSource}
                    source={mediaSource}
                    resizeMode='cover'
                    width={size}
                    height={size}
                />
            )}
        </AvatarRoot>
    );
};
