import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';
import reject from 'lodash/reject';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { Session } from '../models';

const localInitialState = {
    sessions: [],
    isLoading: null,
    error: null,
    didInitialLoad: false
};

const sessionFieldsQueryFragment = `
    fragment SessionFields on Session {
        id
        name
        difficulty
        description
        _version
        _deleted
    }
`;

const getSessionsByOwnerQuery = `
    ${sessionFieldsQueryFragment}
    query QuerySessionsByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: sessionsByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...SessionFields
            }
            nextToken
        }
    }
`;

const getSaveSessionQuery = ({ isUpdate }) => `
    ${sessionFieldsQueryFragment}
    mutation SaveSession
    (
        $name: String!
        $difficulty: SessionDifficulty!
        $description: String
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateSession' : 'createSession'}(
            input: {
                name: $name
                difficulty: $difficulty
                description: $description
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...SessionFields
        }
    }
`;

const deleteSessionQuery = `
    ${sessionFieldsQueryFragment}
    mutation DeleteSession
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteSession(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...SessionFields
        }
    }
`;

const sessionMoveFieldsQueryFragment = `
    fragment SessionMoveFields on SessionMove {
        id
        sessionId
        moveId
        order
        _version
        _deleted
    }
`;

const getSessionMovesByOwnerQuery = `
    ${sessionMoveFieldsQueryFragment}
    query QuerySessionMovesByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: sessionMovesByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...SessionMoveFields
            }
            nextToken
        }
    }
`;

const getSaveSessionMoveQuery = ({ isUpdate }) => `
    ${sessionMoveFieldsQueryFragment}
    mutation SaveSessionMove
    (
        $sessionId: ID!
        $moveId: ID!
        $order: Int!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateSessionMove' : 'createSessionMove'}(
            input: {
                sessionId: $sessionId
                moveId: $moveId
                order: $order
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...SessionMoveFields
        }
    }
`;

const deleteSessionMoveQuery = `
    ${sessionMoveFieldsQueryFragment}
    mutation DeleteSessionMove
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteSessionMove(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...SessionMoveFields
        }
    }
`;

const orderSessionMoves = sessionMoves => sortBy(
    sessionMoves,
    ['order']
);

const setFromCache = () => async ({ setState }) => {
    if (getCache().isSessionsRetrieved()) {
        return;
    }

    getCache().setSessionsIsRetrieved();
    const sessions = await getCache().getSessions();
    setState({ sessions });
};

const setSessions = sessions => ({ setState }) => {
    setState({ sessions });
    getCache().setSessions(sessions);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const fetchSessions = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const sessions = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getSessionsByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        sessions.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    const sessionMoves = [];
    const fetchSessionMoveData = async ({ nextToken }) => {
        const result = await graphql({
            query: getSessionMovesByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        sessionMoves.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchSessionMoveData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await Promise.all([
            fetchData({ nextToken: null }),
            fetchSessionMoveData({ nextToken: null })
        ]);

        dispatch(setIsLoading(false));
        dispatch(
            setSessions(
                map(
                    sessions,
                    session => ({
                        ...session,
                        sessionMoves: orderSessionMoves(
                            filter(
                                sessionMoves,
                                { sessionId: session.id }
                            )
                        )
                    })
                )
            )
        );
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const saveSession = ({
    session: {
        sessionMoves,
        ...session
    },
    sessionPreviousState
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!sessionPreviousState;
    const savedSession = (await graphql({
        query: getSaveSessionQuery({ isUpdate }),
        variables: { ...session }
    })).data.result;

    const newSessionMoves = [];
    await Promise.all([
        // delete removed moves
        ...(isUpdate ? map(
            sessionPreviousState.sessionMoves,
            async ({ id, _version }) => {
                const isDeleted = !find(sessionMoves, { id });
                if (!isDeleted) {
                    return;
                }

                try {
                    await graphql({
                        query: deleteSessionMoveQuery,
                        variables: { id, _version }
                    });
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        ) : []),
        // update / add moves
        ...map(
            sessionMoves,
            async sessionMove => {
                if (sessionMove.id) {
                    newSessionMoves.push(sessionMove);
                    return;
                }

                try {
                    sessionMove.sessionId = savedSession.id;
                    const savedSessionMove = (await graphql({
                        query: getSaveSessionMoveQuery({ isUpdate: false }),
                        variables: { ...sessionMove }
                    })).data.result;
                    newSessionMoves.push(savedSessionMove);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        )
    ]);

    savedSession.sessionMoves = orderSessionMoves(newSessionMoves);

    const sessions = [...getState().sessions];

    if (isUpdate) {
        remove(sessions, ({ id }) => id === savedSession.id);
        const { name: previousName } = sessionPreviousState;
        const { name } = savedSession;
        if (previousName !== name) {
            dispatch(actions.getLinkedStore('moveTimelines')).onSessionSaved(savedSession);
        }
    }

    sessions.push(savedSession);
    dispatch(setSessions(sessions));
    return savedSession;
};

const deleteSession = ({
    session: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedSession = (await graphql({
        query: deleteSessionQuery,
        variables: { id, _version }
    })).data.result;

    const sessions = [...getState().sessions];
    remove(sessions, ({ id }) => id === deletedSession.id);
    dispatch(actions.getLinkedStore('moveTimelines')).onSessionDeleted(deletedSession);
    dispatch(setSessions(sessions));
    return deletedSession;
};

const deleteAllSessions = () => async ({ dispatch }) => {
    await DataStore.delete(Session, Predicates.ALL);
    dispatch(actions.getLinkedStore('moveTimelines')).onAllSessionsDeleted();
    dispatch(setSessions([]));
};

const onMoveDeleted = ({ id }) => ({ getState, dispatch }) => {
    const { sessions } = getState();

    dispatch(
        setSessions(
            map(
                sessions,
                session => ({
                    ...session,
                    sessionMoves: reject(session.sessionMoves, ['moveId', id])
                })
            )
        )
    );
};

const onAllMovesDeleted = () => ({ getState, dispatch }) => {
    const { sessions } = getState();
    dispatch(
        setSessions(
            map(
                sessions,
                session => ({
                    ...session,
                    sessionMoves: []
                })
            )
        )
    );
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchSessions,
        saveSession,
        deleteSession,
        deleteAllSessions,
        onMoveDeleted,
        onAllMovesDeleted,
        onInitialLoad
    },
    name: 'Sessions'
});

const useSessionsState = createStateHook(
    Store,
    {
        selector: (
            {
                sessions,
                isLoading,
                error,
                didInitialLoad
            }
        ) => ({
            sessions,
            isLoading: isLoading || isLoading === null,
            error,
            didInitialLoad
        })
    }
);

export const useSessionsActions = createActionsHook(Store);

export const useSessions = () => {
    const { fetchSessions, onInitialLoad } = useSessionsActions();
    const {
        sessions,
        isLoading,
        error,
        didInitialLoad
    } = useSessionsState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchSessions();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchSessions,
        onInitialLoad
    ]);

    return {
        sessions,
        isLoading,
        error
    };
};

export const useSessionsCount = () => {
    const {
        sessions,
        isLoading,
        error
    } = useSessions();

    if (isLoading || error || !sessions) {
        return undefined;
    }

    return sessions.length;
};

export const useSession = ({ id }) => {
    const {
        sessions,
        isLoading,
        error
    } = useSessions();

    return {
        session: find(sessions, { id }),
        isLoading,
        error
    };
};

export const useFetchSessions = () => {
    const { fetchSessions } = useSessionsActions();
    return fetchSessions;
};

export const useSaveSession = () => {
    const { saveSession } = useSessionsActions();
    return saveSession;
};

export const useDeleteSession = () => {
    const { deleteSession } = useSessionsActions();
    return deleteSession;
};

export const useDeleteAllSessions = () => {
    const { deleteAllSessions } = useSessionsActions();
    return deleteAllSessions;
};
