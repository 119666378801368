import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncOnlineLibraryGuide = lazyForPaint(
    () => import(/* webpackChunkName: "async-online-library-guide" */ './index')
        .then(({ OnlineLibraryGuide }) => OnlineLibraryGuide)
);

export const OnlineLibraryGuide = () => {
    di(AsyncOnlineLibraryGuide, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncOnlineLibraryGuide />
        </LazySuspense>
    );
};
