import { useCallback, useRef } from 'react';
import { requestCountry } from '@codexporer.io/request-country';
import { requestIp } from '@codexporer.io/request-ip';
import { useAppInfo } from '@codexporer.io/expo-app-info';
import { OS } from '@codexporer.io/expo-device';
import toLower from 'lodash/toLower';
import {
    initialize as initializeAnalytics,
    sendEvent,
    sendScreenEvent
} from '@codexporer.io/app-analytics';
import { useUserSubscriptionType } from '@codexporer.io/expo-user-subscription';
import { useMe } from '@pole-journal/aws-amplify/src/datastore';

export const useAppAnalytics = ({ firebaseConfig, awsConfig } = {}) => {
    const [{
        appName,
        appVersion,
        sessionId,
        installationId: clientId,
        appContainer,
        startTime
    }] = useAppInfo();
    const userSubscriptionType = useUserSubscriptionType();
    const dependenciesRef = useRef({});
    const { me } = useMe();

    const getAttributes = async ({ attributes }) => {
        const country = await requestCountry();
        const ip = await requestIp();
        return {
            ...(attributes ?? {}),
            os: toLower(OS.getOS()),
            app_version: appVersion,
            ...(
                appContainer ?
                    { app_container: appContainer } :
                    {}
            ),
            client_id: clientId,
            session_id: sessionId,
            utc_timestamp: new Date().toISOString(),
            ...(
                country ?
                    {
                        country_code: country.isoCode,
                        country_name: country.name
                    } :
                    {}
            ),
            ...(ip ? { ip_address: ip } : {}),
            is_localhost: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1',
            user_subscription_type: userSubscriptionType,
            db_user_id: me?.id,
            app_start_before_seconds: Math.round((new Date() - startTime) / 1000)
        };
    };

    dependenciesRef.current = {
        appName,
        appVersion,
        awsConfig,
        clientId,
        firebaseConfig,
        sessionId,
        getAttributes
    };

    const initializeFn = useCallback(() => {
        const {
            appName,
            appVersion,
            awsConfig: { analytics },
            clientId,
            firebaseConfig,
            sessionId
        } = dependenciesRef.current;
        initializeAnalytics({
            firebase: firebaseConfig && {
                config: firebaseConfig,
                options: {
                    clientId,
                    sessionId,
                    appName,
                    appVersion
                }
            },
            amplify: analytics
        });
    }, []);

    const sendEventFn = useCallback(async ({ name, attributes }) => {
        const { getAttributes } = dependenciesRef.current;
        attributes = await getAttributes({ attributes });
        sendEvent({ name, attributes });
    }, []);

    const sendScreenEventFn = useCallback(async ({ screenName, attributes }) => {
        const { getAttributes } = dependenciesRef.current;
        attributes = await getAttributes({ attributes });
        sendScreenEvent({ screenName, attributes });
    }, []);

    return {
        initialize: initializeFn,
        sendEvent: sendEventFn,
        sendScreenEvent: sendScreenEventFn
    };
};
