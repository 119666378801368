import React from 'react';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';

const AsyncSplash = lazyForPaint(
    () => import(/* webpackChunkName: "async-splash" */ './index')
        .then(({ Splash }) => Splash)
);

export const Splash = () => (
    <LazySuspense>
        <AsyncSplash />
    </LazySuspense>
);
