import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';
import memoize from 'lodash/memoize';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { TimelineInstructor } from '../models';

const localInitialState = {
    timelineInstructors: [],
    isLoading: null,
    error: null,
    didInitialLoad: false
};

const timelineInstructorFieldsQueryFragment = `
    fragment TimelineInstructorFields on TimelineInstructor {
        id
        name
        _version
        _deleted
    }
`;

const getTimelineInstructorsByOwnerQuery = `
    ${timelineInstructorFieldsQueryFragment}
    query QueryTimelineInstructorsByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: timelineInstructorsByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...TimelineInstructorFields
            }
            nextToken
        }
    }
`;

const getSaveTimelineInstructorQuery = ({ isUpdate }) => `
    ${timelineInstructorFieldsQueryFragment}
    mutation TimelineInstructor
    (
        $name: String!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateTimelineInstructor' : 'createTimelineInstructor'}(
            input: {
                name: $name
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...TimelineInstructorFields
        }
    }
`;

const deleteTimelineInstructorQuery = `
    ${timelineInstructorFieldsQueryFragment}
    mutation DeleteTimelineInstructor
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteTimelineInstructor(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...TimelineInstructorFields
        }
    }
`;

const orderTimelineInstructors = memoize(
    timelineInstructors => sortBy(
        timelineInstructors,
        ['name']
    ),
    (...args) => JSON.stringify(args)
);

const clearSelectors = () => {
    orderTimelineInstructors.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isTimelineInstructorsRetrieved()) {
        return;
    }

    getCache().setTimelineInstructorsIsRetrieved();
    const timelineInstructors = await getCache().getTimelineInstructors();
    clearSelectors();
    setState({ timelineInstructors });
};

const setTimelineInstructors = timelineInstructors => ({ setState }) => {
    clearSelectors();
    setState({ timelineInstructors });
    getCache().setTimelineInstructors(timelineInstructors);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchTimelineInstructors = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const timelineInstructors = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getTimelineInstructorsByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        timelineInstructors.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setTimelineInstructors(timelineInstructors));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveTimelineInstructor = ({
    timelineInstructor,
    timelineInstructorPreviousState
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!timelineInstructorPreviousState;
    const savedTimelineInstructor = (await graphql({
        query: getSaveTimelineInstructorQuery({ isUpdate }),
        variables: { ...timelineInstructor }
    })).data.result;

    const timelineInstructors = [...getState().timelineInstructors];
    if (isUpdate) {
        remove(timelineInstructors, ({ id }) => id === savedTimelineInstructor.id);
        const previousName = timelineInstructorPreviousState.name;
        const { name } = savedTimelineInstructor;
        previousName !== name && dispatch(actions.getLinkedStore('moveTimelines')).onTimelineInstructorSaved(savedTimelineInstructor);
    }

    timelineInstructors.push(savedTimelineInstructor);
    dispatch(setTimelineInstructors(timelineInstructors));
    return savedTimelineInstructor;
};

const deleteTimelineInstructor = ({
    timelineInstructor: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedTimelineInstructor = (await graphql({
        query: deleteTimelineInstructorQuery,
        variables: { id, _version }
    })).data.result;
    const timelineInstructors = [...getState().timelineInstructors];
    remove(timelineInstructors, ({ id }) => id === deletedTimelineInstructor.id);
    dispatch(actions.getLinkedStore('moveTimelines')).onTimelineInstructorDeleted(deletedTimelineInstructor);
    dispatch(setTimelineInstructors(timelineInstructors));
    return deletedTimelineInstructor;
};

const deleteAllTimelineInstructors = () => async ({ dispatch }) => {
    await DataStore.delete(TimelineInstructor, Predicates.ALL);
    dispatch(actions.getLinkedStore('moveTimelines')).onAllTimelineInstructorsDeleted();
    dispatch(setTimelineInstructors([]));
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchTimelineInstructors,
        onInitialLoad,
        saveTimelineInstructor,
        deleteTimelineInstructor,
        deleteAllTimelineInstructors
    },
    name: 'TimelineInstructors'
});

const useTimelineInstructorsState = createStateHook(
    Store,
    {
        selector: (
            {
                timelineInstructors,
                isLoading,
                error,
                didInitialLoad
            }
        ) => ({
            timelineInstructors: orderTimelineInstructors(timelineInstructors),
            isLoading: isLoading || isLoading === null,
            error,
            didInitialLoad
        })
    }
);

export const useTimelineInstructorsActions = createActionsHook(Store);

export const useTimelineInstructors = () => {
    const { fetchTimelineInstructors, onInitialLoad } = useTimelineInstructorsActions();
    const {
        timelineInstructors,
        isLoading,
        error,
        didInitialLoad
    } = useTimelineInstructorsState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchTimelineInstructors();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchTimelineInstructors,
        onInitialLoad
    ]);

    return {
        timelineInstructors,
        isLoading,
        error
    };
};

export const useFetchTimelineInstructors = () => {
    const { fetchTimelineInstructors } = useTimelineInstructorsActions();
    return fetchTimelineInstructors;
};

export const useSaveTimelineInstructor = () => {
    const { saveTimelineInstructor } = useTimelineInstructorsActions();
    return saveTimelineInstructor;
};

export const useDeleteTimelineInstructor = () => {
    const { deleteTimelineInstructor } = useTimelineInstructorsActions();
    return deleteTimelineInstructor;
};

export const useDeleteAllTimelineInstructors = () => {
    const { deleteAllTimelineInstructors } = useTimelineInstructorsActions();
    return deleteAllTimelineInstructors;
};
