import { MoveDiscipline } from '../models';

const MoveDisciplines = [
    MoveDiscipline.POLE,
    MoveDiscipline.AERIAL_HOOP,
    MoveDiscipline.AERIAL_SILK,
    MoveDiscipline.AERIAL_SLING,
    MoveDiscipline.AERIAL_ROPE,
    MoveDiscipline.AERIAL_STATIC_TRAPEZE,
    MoveDiscipline.HULA_HOOP,
    MoveDiscipline.FLEXIBILITY,
    MoveDiscipline.STRENGTH,
    MoveDiscipline.OTHER
];

export const getMoveDisciplines = () => MoveDisciplines;
