import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

export const getOwnerFilter = ({
    owner
}) => ({
    owner: { eq: owner }
});

export const getOwner = async () => {
    const [
        { username: owner },
        { userSub: sub }
    ] = await Promise.all([
        getCurrentUser(),
        fetchAuthSession()
    ]);

    if (!sub) {
        throw new Error('Owner sub could not be retrieved');
    }

    return {
        ownerLegacy: owner,
        sub,
        owner: `${sub}::${owner}`
    };
};

export const getCognitoUsername = async () => {
    const { username } = await getCurrentUser();

    return username;
};
