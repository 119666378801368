import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncTermsAndConditions = lazyForPaint(
    () => import(/* webpackChunkName: "async-terms-and-conditions" */ './index')
        .then(({ TermsAndConditions }) => TermsAndConditions)
);

export const TermsAndConditions = () => {
    di(AsyncTermsAndConditions, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncTermsAndConditions />
        </LazySuspense>
    );
};
