import { uploadData } from 'aws-amplify/storage';
import { getCurrentUser } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import { withPromiseTimeout } from '@codexporer.io/promise-timeout';
import { TimelineMediaType } from '../models';
import { awsConfig } from '../aws.config';

export const StorageAccessLevel = {
    private: 'private',
    protected: 'protected',
    public: 'public'
};

export const StoreType = {
    legacyPrivateMedia: 'legacyPrivateMedia',
    userProfileImage: 'userAvatar',
    userProfileCoverImage: 'userProfileCover',
    userMoveCoverVideo: 'userMoveCoverVideo',
    onlineLibraryMoves: 'onlineLibraryMoves',
    onlineLibraryUserSubmissions: 'onlineLibraryUserSubmissions'
};

const contentDirMap = ({
    cognitoUserName,
    identityId,
    isSave = false
}) => ({
    [StorageAccessLevel.private]: {
        [StoreType.legacyPrivateMedia]: `${isSave ? '' : `${identityId}/`}private-content/user/${cognitoUserName}`,
        [StoreType.userMoveCoverVideo]: `${isSave ? '' : `${identityId}/`}move/cover-video`,
        [StoreType.onlineLibraryUserSubmissions]: `${isSave ? '' : `${identityId}/`}online-library/submission`
    },
    [StorageAccessLevel.protected]: {
        [StoreType.onlineLibraryMoves]: 'non-user-content/online-library/move',
        [StoreType.userProfileImage]: `${isSave ? '' : `${identityId}/`}profile/avatar`,
        [StoreType.userProfileCoverImage]: `${isSave ? '' : `${identityId}/`}profile/cover`
    }
});

const oneHourSeconds = 60 * 60;
const oneDaySeconds = 24 * oneHourSeconds;
const getTimeoutInMillis = 1000 * 60 * 2;
const pushTimeoutInMillis = 1000 * 60 * 10;

const getContentId = async ({
    id,
    accessLevel,
    storeType,
    identityId,
    isSave
}) => {
    let cognitoUserName;
    try {
        cognitoUserName = (await getCurrentUser()).username;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return `${contentDirMap({ cognitoUserName, identityId, isSave })[accessLevel][storeType]}/${id}`;
};

const cachedUrls = {};

const getCacheKey = ({
    id,
    accessLevel,
    storeType,
    identityId
}) => `${id}-${accessLevel}-${storeType}-${identityId}`;

const canGetFromCache = cache => {
    if (!cache) {
        return false;
    }

    const { expiryDate } = cache;
    const dateNow = new Date();
    const expiryInMs = oneDaySeconds * 1000;

    return dateNow.getTime() - expiryDate.getTime() < expiryInMs;
};

export const getFileUrlById = ({
    id,
    accessLevel = StorageAccessLevel.private,
    storeType = StoreType.legacyPrivateMedia,
    identityId
}) => withPromiseTimeout(
    (async () => {
        const contentId = await getContentId({
            id,
            accessLevel,
            storeType,
            identityId
        });
        const cacheKey = getCacheKey({
            id: contentId,
            accessLevel,
            storeType,
            identityId
        });
        const cache = cachedUrls[cacheKey];
        if (canGetFromCache(cache)) {
            return cache.promise;
        }

        cachedUrls[cacheKey] = {
            promise: (async () => {
                const getMediaUrlRestOperation = post({
                    apiName: awsConfig.restApi.name,
                    path: '/get-media-url',
                    options: {
                        body: {
                            bucket: awsConfig.awsmobile.aws_user_files_s3_bucket,
                            shouldIncludeIdentityId: false,
                            accessLevel,
                            contentId,
                            expires: 2 * oneDaySeconds
                        }
                    }
                });
                const { body } = await getMediaUrlRestOperation.response;
                const { url } = await body.json();
                return url;
            })(),
            expiryDate: new Date()
        };

        return cachedUrls[cacheKey].promise;
    })(),
    { timeoutInMillis: getTimeoutInMillis }
);

export const getMediaThumbnailUrlById = ({
    id,
    accessLevel,
    storeType,
    mediaType = TimelineMediaType.IMAGE,
    identityId
}) => getFileUrlById({
    id: `pjGeneratedThubnail_${id}${mediaType === TimelineMediaType.VIDEO ? '.jpg' : ''}`,
    accessLevel,
    storeType,
    identityId
});

export const saveFile = ({
    id,
    accessLevel = StorageAccessLevel.private,
    storeType = StoreType.legacyPrivateMedia,
    url
}) => withPromiseTimeout(
    (async () => {
        const contentId = await getContentId({
            id,
            accessLevel,
            storeType,
            isSave: true
        });
        const response = await fetch(url);
        const blob = await response.blob();
        await uploadData({
            key: contentId,
            data: blob,
            options: {
                accessLevel: accessLevel === 'public' ? 'guest' : accessLevel
            }
        }).result;
    })(),
    { timeoutInMillis: pushTimeoutInMillis }
);
