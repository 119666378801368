import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncPrivacyPolicy = lazyForPaint(
    () => import(/* webpackChunkName: "async-privacy-policy" */ './index')
        .then(({ PrivacyPolicy }) => PrivacyPolicy)
);

export const PrivacyPolicy = () => {
    di(AsyncPrivacyPolicy, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncPrivacyPolicy />
        </LazySuspense>
    );
};
