import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import noop from 'lodash/noop';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { saveFile, StoreType } from '../storage';
import { OnlineLibraryUserSubmission } from '../models';

const localInitialState = {
    submissions: [],
    isLoading: false,
    error: null,
    didInitialLoad: false
};

const onlineLibrarySubmissionFieldsQueryFragment = `
    fragment OnlineLibraryUserSubmissionFields on OnlineLibraryUserSubmission {
        id
        submissionType
        submissionStatus
        submissionNotes
        isAnonymous
        feedbackNotes
        moveName
        moveAltNames
        moveDifficulty
        moveDiscipline
        moveLinks {
            link
            displayText
        }
        moveDescription
        moveCoverImageId
        moveCoverVideoId
        moveCoverVideoUrl
        moveId
        userId
        move {
            name
        }
        createdAt
        _version
        _deleted
    }
`;

export const getOnlineLibrarySubmissionsByOwnerQuery = `
    ${onlineLibrarySubmissionFieldsQueryFragment}
    query QueryOnlineLibraryUserSubmissionsByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: submissionsByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...OnlineLibraryUserSubmissionFields
            }
            nextToken
        }
    }
`;

const getSaveOnlineLibrarySubmissionQuery = ({ isUpdate }) => `
    ${onlineLibrarySubmissionFieldsQueryFragment}
    mutation OnlineLibraryUserSubmission
    (
        $submissionType: OnlineLibraryUserSubmissionType!
        $submissionStatus: OnlineLibraryUserSubmissionStatus!
        $submissionNotes: String
        $isAnonymous: Boolean
        $moveName: String
        $moveAltNames: [String!]
        $moveDifficulty: MoveLevel
        $moveDiscipline: MoveDiscipline
        $moveLinks: [LinkInput!]
        $moveDescription: String
        $moveCoverImageId: String
        $moveCoverVideoId: String
        $moveCoverVideoUrl: String
        $moveId: ID
        $userId: ID!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateOnlineLibraryUserSubmission' : 'createOnlineLibraryUserSubmission'}(
            input: {
                submissionType: $submissionType
                submissionStatus: $submissionStatus
                submissionNotes: $submissionNotes
                isAnonymous: $isAnonymous
                moveName: $moveName
                moveAltNames: $moveAltNames
                moveDifficulty: $moveDifficulty
                moveDiscipline: $moveDiscipline
                moveLinks: $moveLinks
                moveDescription: $moveDescription
                moveCoverImageId: $moveCoverImageId
                moveCoverVideoId: $moveCoverVideoId
                moveCoverVideoUrl: $moveCoverVideoUrl
                moveId: $moveId
                userId: $userId
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...OnlineLibraryUserSubmissionFields
        }
    }
`;

const deleteOnlineLibrarySubmissionQuery = `
    ${onlineLibrarySubmissionFieldsQueryFragment}
    mutation DeleteOnlineLibraryUserSubmission
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteOnlineLibraryUserSubmission(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...OnlineLibraryUserSubmissionFields
        }
    }
`;

const setFromCache = () => async ({ setState }) => {
    if (getCache().isOnlineLibraryUserSubmissionsRetrieved()) {
        return;
    }

    getCache().setOnlineLibraryUserSubmissionsIsRetrieved();
    const submissions = await getCache().getOnlineLibraryUserSubmissions();
    setState({ submissions });
};

const setSubmissions = submissions => ({ setState }) => {
    setState({ submissions });
    getCache().setOnlineLibraryUserSubmissions(submissions);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchSubmissions = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const submissions = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getOnlineLibrarySubmissionsByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        submissions.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setSubmissions(submissions));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveSubmission = ({
    submission,
    submissionPreviousState,
    moveCoverImageUrl,
    moveCoverVideoUrl
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!submissionPreviousState;

    const savedSubmission = (await graphql({
        query: getSaveOnlineLibrarySubmissionQuery({ isUpdate }),
        variables: { ...submission }
    })).data.result;

    const {
        moveCoverImageId: previousMoveCoverImageId,
        moveCoverVideoId: previousMoveCoverVideoId
    } = submissionPreviousState ?? {};
    const {
        moveCoverImageId,
        moveCoverVideoId
    } = savedSubmission;

    await Promise.all([
        previousMoveCoverImageId !== moveCoverImageId && moveCoverImageId && moveCoverImageUrl ?
            saveFile({
                id: moveCoverImageId,
                url: moveCoverImageUrl,
                storeType: StoreType.onlineLibraryUserSubmissions
            }).catch(noop) :
            Promise.resolve(),
        previousMoveCoverVideoId !== moveCoverVideoId && moveCoverVideoId && moveCoverVideoUrl ?
            saveFile({
                id: moveCoverVideoId,
                url: moveCoverVideoUrl,
                storeType: StoreType.onlineLibraryUserSubmissions
            }).catch(noop) :
            Promise.resolve()
    ]);

    const submissions = [...getState().submissions];
    if (isUpdate) {
        remove(submissions, ({ id }) => id === savedSubmission.id);
    }

    submissions.push(savedSubmission);
    dispatch(setSubmissions(submissions));
    return savedSubmission;
};

const deleteSubmission = ({
    submission: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedSubmission = (await graphql({
        query: deleteOnlineLibrarySubmissionQuery,
        variables: { id, _version }
    })).data.result;

    const submissions = [...getState().submissions];
    remove(submissions, ({ id }) => id === deletedSubmission.id);
    dispatch(setSubmissions(submissions));
    return deletedSubmission;
};

const deleteAllSubmissions = () => async ({ dispatch }) => {
    await DataStore.delete(OnlineLibraryUserSubmission, Predicates.ALL);
    dispatch(setSubmissions([]));
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchSubmissions,
        onInitialLoad,
        saveSubmission,
        deleteSubmission,
        deleteAllSubmissions
    },
    name: 'OnlineLibraryUserSubmissions'
});

const useOnlineLibraryUserSubmissionsState = createStateHook(
    Store,
    {
        selector: (
            {
                submissions,
                isLoading,
                error,
                didInitialLoad
            }
        ) => ({
            submissions,
            isLoading,
            error,
            didInitialLoad
        })
    }
);

export const useOnlineLibraryUserSubmissionsActions = createActionsHook(Store);

export const useOnlineLibraryUserSubmissions = () => {
    const { fetchSubmissions, onInitialLoad } = useOnlineLibraryUserSubmissionsActions();
    const {
        submissions,
        isLoading,
        error,
        didInitialLoad
    } = useOnlineLibraryUserSubmissionsState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchSubmissions();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchSubmissions,
        onInitialLoad
    ]);

    return {
        submissions,
        isLoading,
        error
    };
};

export const useFetchSubmissions = () => {
    const { fetchSubmissions } = useOnlineLibraryUserSubmissionsActions();
    return fetchSubmissions;
};

export const useSaveSubmission = () => {
    const { saveSubmission } = useOnlineLibraryUserSubmissionsActions();
    return saveSubmission;
};

export const useDeleteSubmission = () => {
    const { deleteSubmission } = useOnlineLibraryUserSubmissionsActions();
    return deleteSubmission;
};

export const useDeleteAllSubmissions = () => {
    const { deleteAllSubmissions } = useOnlineLibraryUserSubmissionsActions();
    return deleteAllSubmissions;
};
