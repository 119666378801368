import DeviceInfo from 'react-native-device-info';

let os = 'unknown';
export const init = async () => {
    try {
        os = await DeviceInfo.getBaseOs();
    } catch {
        os = 'unknown';
    }
};

export const OS = {
    isIOS: () => os === 'iOS',
    isAndroid: () => os === 'Android',
    getOS: () => os
};
