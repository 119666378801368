import noop from 'lodash/noop';

export const getSubscriptions = noop;

export const getAvailablePurchases = noop;

export const initConnection = noop;

export const endConnection = noop;

export const flushFailedPurchasesCachedAsPendingAndroid = noop;

export const purchaseUpdatedListener = noop;

export const purchaseErrorListener = noop;

export const finishTransaction = noop;

export const requestSubscription = noop;
