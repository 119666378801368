import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';
import memoize from 'lodash/memoize';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { MoveCustomLevel } from '../models';

const localInitialState = {
    customLevels: [],
    isLoading: null,
    error: null,
    didInitialLoad: false
};

const moveCustomLevelFieldsQueryFragment = `
    fragment MoveCustomLevelFields on MoveCustomLevel {
        id
        name
        _version
        _deleted
    }
`;

const getMoveCustomLevelsByOwnerQuery = `
    ${moveCustomLevelFieldsQueryFragment}
    query QueryMoveCustomLevelsByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: levelsByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MoveCustomLevelFields
            }
            nextToken
        }
    }
`;

const getSaveMoveCustomLevelQuery = ({ isUpdate }) => `
    ${moveCustomLevelFieldsQueryFragment}
    mutation MoveCustomLevel
    (
        $name: String!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateMoveCustomLevel' : 'createMoveCustomLevel'}(
            input: {
                name: $name
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...MoveCustomLevelFields
        }
    }
`;

const deleteMoveCustomLevelQuery = `
    ${moveCustomLevelFieldsQueryFragment}
    mutation DeleteMoveCustomLevel
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteMoveCustomLevel(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...MoveCustomLevelFields
        }
    }
`;

const orderMoveCustomLevels = memoize(
    customLevels => sortBy(
        customLevels,
        ['name']
    ),
    (...args) => JSON.stringify(args)
);

const clearSelectors = () => {
    orderMoveCustomLevels.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isMoveCustomLevelsRetrieved()) {
        return;
    }

    getCache().setMoveCustomLevelsIsRetrieved();
    const customLevels = await getCache().getMoveCustomLevels();
    clearSelectors();
    setState({ customLevels });
};

const setMoveCustomLevels = customLevels => ({ setState }) => {
    clearSelectors();
    setState({ customLevels });
    getCache().setMoveCustomLevels(customLevels);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchMoveCustomLevels = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const customLevels = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getMoveCustomLevelsByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        customLevels.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setMoveCustomLevels(customLevels));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveMoveCustomLevel = ({
    customLevel,
    customLevelPreviousState
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!customLevelPreviousState;
    const savedCustomLevel = (await graphql({
        query: getSaveMoveCustomLevelQuery({ isUpdate }),
        variables: { ...customLevel }
    })).data.result;

    const customLevels = [...getState().customLevels];
    if (isUpdate) {
        remove(customLevels, ({ id }) => id === savedCustomLevel.id);
        const previousName = customLevelPreviousState.name;
        const { name } = savedCustomLevel;
        previousName !== name && dispatch(actions.getLinkedStore('moves')).onMoveCustomLevelSaved(savedCustomLevel);
    }

    customLevels.push(savedCustomLevel);
    dispatch(setMoveCustomLevels(customLevels));
    return savedCustomLevel;
};

const deleteMoveCustomLevel = ({
    customLevel: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedCustomLevel = (await graphql({
        query: deleteMoveCustomLevelQuery,
        variables: { id, _version }
    })).data.result;

    const customLevels = [...getState().customLevels];
    remove(customLevels, ({ id }) => id === deletedCustomLevel.id);
    dispatch(actions.getLinkedStore('moves')).onMoveCustomLevelDeleted(deletedCustomLevel);
    dispatch(setMoveCustomLevels(customLevels));
    return deletedCustomLevel;
};

const deleteAllMoveCustomLevels = () => async ({ dispatch }) => {
    await DataStore.delete(MoveCustomLevel, Predicates.ALL);
    dispatch(actions.getLinkedStore('moves')).onAllMoveCustomLevelsDeleted();
    dispatch(setMoveCustomLevels([]));
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchMoveCustomLevels,
        onInitialLoad,
        saveMoveCustomLevel,
        deleteMoveCustomLevel,
        deleteAllMoveCustomLevels
    },
    name: 'MoveCustomLevels'
});

const useMoveCustomLevelsState = createStateHook(
    Store,
    {
        selector: (
            {
                customLevels,
                isLoading,
                error,
                didInitialLoad
            }
        ) => ({
            customLevels: orderMoveCustomLevels(customLevels),
            isLoading: isLoading || isLoading === null,
            error,
            didInitialLoad
        })
    }
);

export const useMoveCustomLevelsActions = createActionsHook(Store);

export const useMoveCustomLevels = () => {
    const { fetchMoveCustomLevels, onInitialLoad } = useMoveCustomLevelsActions();
    const {
        customLevels,
        isLoading,
        error,
        didInitialLoad
    } = useMoveCustomLevelsState();
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchMoveCustomLevels();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchMoveCustomLevels,
        onInitialLoad
    ]);

    return {
        customLevels,
        isLoading,
        error
    };
};

export const useFetchMoveCustomLevels = () => {
    const { fetchMoveCustomLevels } = useMoveCustomLevelsActions();
    return fetchMoveCustomLevels;
};

export const useSaveMoveCustomLevel = () => {
    const { saveMoveCustomLevel } = useMoveCustomLevelsActions();
    return saveMoveCustomLevel;
};

export const useDeleteMoveCustomLevel = () => {
    const { deleteMoveCustomLevel } = useMoveCustomLevelsActions();
    return deleteMoveCustomLevel;
};

export const useDeleteAllMoveCustomLevels = () => {
    const { deleteAllMoveCustomLevels } = useMoveCustomLevelsActions();
    return deleteAllMoveCustomLevels;
};
