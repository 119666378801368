const webLink = 'https://acro.fitness';

export const webTocLink = `${webLink}/terms-and-conditions`;
export const webPrivacyLink = `${webLink}/privacy-policy`;
export const webSupportLink = `${webLink}/contact`;
export const webSubscriptionsLink = `${webLink}/subscription-plans`;
export const webOnlineLibraryGuide = `${webLink}/online-library-guide/online-library`;
export const webSubmissionGuide = `${webLink}/online-library-guide/submission-guides`;
export const getWebOnlineLibraryMoveLink = ({ moveId }) => `${webLink}/online-library-move/${moveId}`;
export const playStoreLink = `${webLink}/go/play-store`;
export const appStoreLink = `${webLink}/go/app-store`;
export const instagramLink = `${webLink}/go/insta`;
export const facebookLink = `${webLink}/go/fb`;
export const tikTokLink = `${webLink}/go/tiktok`;
export const redditLink = `${webLink}/go/reddit`;
export const youtubeLink = `${webLink}/go/youtube`;
export const threadsLink = `${webLink}/go/threads`;
