/* eslint-disable camelcase */
import Entypo_ttf from 'react-native-vector-icons/Fonts/Entypo.ttf';
import FontAwesome6_Brands_ttf from 'react-native-vector-icons/Fonts/FontAwesome6_Brands.ttf';
import FontAwesome6_Regular_ttf from 'react-native-vector-icons/Fonts/FontAwesome6_Regular.ttf';
import FontAwesome6_Solid_ttf from 'react-native-vector-icons/Fonts/FontAwesome6_Solid.ttf';

const IconsCSS = `
@font-face {
  src: url(${Entypo_ttf});
  font-family: Entypo;
}
@font-face {
  src: url(${FontAwesome6_Brands_ttf});
  font-family: FontAwesome6_Brands;
}
@font-face {
  src: url(${FontAwesome6_Regular_ttf});
  font-family: FontAwesome6_Regular;
}
@font-face {
  src: url(${FontAwesome6_Solid_ttf});
  font-family: FontAwesome6_Solid;
}
`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
else style.appendChild(document.createTextNode(IconsCSS));

document.head.appendChild(style);
