import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncOnlineLibraryMove = lazyForPaint(
    () => import(/* webpackChunkName: "async-online-library-move" */ './index')
        .then(({ OnlineLibraryMove }) => OnlineLibraryMove)
);

export const OnlineLibraryMove = () => {
    di(AsyncOnlineLibraryMove, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncOnlineLibraryMove />
        </LazySuspense>
    );
};
