import { useMemo } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { OS } from '@codexporer.io/expo-device';
import { post } from 'aws-amplify/api';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import {
    useAppInfo,
    areAppVersionsEqual,
    isCurrentAppVersionGreaterThanAppVersion
} from '@codexporer.io/expo-app-info';
import { getSubscriptions, getAvailablePurchases } from 'react-native-iap';
import { getModule } from './module';

const localInitialState = {
    availableSubscriptions: null,
    isLoading: null,
    error: null
};

const getExtraFieldsString = ({
    extraFields
}) => extraFields?.length > 0 ? reduce(extraFields, (result, field) => `${result}\n${field}`, '') : '';

const getAvailableSubscriptionFieldsQueryFragment = ({
    extraAvailableSubscriptionFields
}) => `
    fragment AvailableSubscriptionFields on AvailableSubscription {
        id
        platform
        productId
        planId
        isActive
        createdAt
        updatedAt
        availableFromAppVersion
        ${getExtraFieldsString({ extraFields: extraAvailableSubscriptionFields })}
        _lastChangedAt
        _version
        _deleted
    }
`;

const getListAvailableSubscriptionsQuery = ({
    extraAvailableSubscriptionFields
}) => `
    ${getAvailableSubscriptionFieldsQueryFragment({ extraAvailableSubscriptionFields })}
    query ListAvailableSubscription(
        $filter: ModelAvailableSubscriptionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        result: listAvailableSubscriptions(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...AvailableSubscriptionFields
            }
            nextToken
        }
    }
`;

const setAvailableSubscriptions = availableSubscriptions => ({ setState }) => {
    setState({ availableSubscriptions });
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchAvailableSubscriptions = ({ appVersion }) => ({ getState, dispatch }) => async () => {
    const {
        graphql,
        SubscriptionPlatform,
        getOwner,
        restApiName,
        extraAvailableSubscriptionFields
    } = getModule();

    const { isLoadingSubscriptions } = getState();
    if (isLoadingSubscriptions) {
        return;
    }

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        if (!OS.isIOS() && !OS.isAndroid()) {
            throw new Error('Unsupported subscription platform.');
        }

        const subscriptions = [];
        const fetchSubscriptions = async ({ nextToken }) => {
            const result = await graphql({
                query: getListAvailableSubscriptionsQuery({
                    extraAvailableSubscriptionFields
                }),
                variables: {
                    limit: 100,
                    nextToken
                }
            });

            subscriptions.push(
                ...filter(
                    result.data.result?.items,
                    ({
                        _deleted,
                        isActive,
                        availableFromAppVersion
                    }) => _deleted !== true && isActive === true && (
                        areAppVersionsEqual(
                            appVersion,
                            availableFromAppVersion
                        ) || isCurrentAppVersionGreaterThanAppVersion(
                            appVersion,
                            availableFromAppVersion
                        )
                    )
                )
            );

            nextToken = result.data.result?.nextToken;
            if (nextToken) {
                await fetchSubscriptions({ nextToken });
            }
        };

        await fetchSubscriptions({ nextToken: null });

        const productIds = uniq(
            map(
                filter(
                    subscriptions,
                    [
                        'platform',
                        OS.isIOS() ?
                            SubscriptionPlatform.APPLE_STORE :
                            SubscriptionPlatform.GOOGLE_PLAY
                    ]
                ),
                'productId'
            )
        );

        const currentPlatform = OS.isIOS() ?
            SubscriptionPlatform.APPLE_STORE :
            SubscriptionPlatform.GOOGLE_PLAY;
        const planIdsSubscriptionsMap = reduce(
            subscriptions,
            (planIdsSubscriptionsMap, subscription) => {
                if (subscription.platform === currentPlatform) {
                    planIdsSubscriptionsMap[subscription.planId] = subscription;
                }

                return planIdsSubscriptionsMap;
            },
            {}
        );

        const products = await getSubscriptions({ skus: productIds });
        const availableSubscriptions = [];

        if (OS.isIOS()) {
            const isFreeEligible = await getAvailablePurchases().then(
                availablePurchases => !availablePurchases?.length,
                () => false
            );
            availableSubscriptions.push(
                ...reduce(
                    products,
                    (availableSubscriptions, product) => {
                        const subscription = planIdsSubscriptionsMap[product.productId];
                        if (!subscription) {
                            return availableSubscriptions;
                        }

                        availableSubscriptions.push({
                            productId: product.productId,
                            subscriptionPeriodNumber: +product.subscriptionPeriodNumberIOS,
                            subscriptionPeriodUnit: product.subscriptionPeriodUnitIOS,
                            localizedPrice: product.localizedPrice,
                            price: +product.price,
                            currency: product.currency,
                            ...isFreeEligible && {
                                freeTrialPeriodNumber: +product.introductoryPriceNumberOfPeriodsIOS,
                                freeTrialPeriodUnit: product.introductoryPriceSubscriptionPeriodIOS
                            },
                            ...pick(
                                subscription,
                                extraAvailableSubscriptionFields
                            )
                        });
                        return availableSubscriptions;
                    },
                    []
                )
            );
        } else {
            // await fetch endpoint to get if free eligible
            const isFreeEligible = await (async () => {
                try {
                    const { owner } = await getOwner();
                    const introductoryPriceEligibilityRestOperation = post({
                        apiName: restApiName,
                        path: '/user-subscription/introductory-price-eligibility',
                        options: {
                            body: { owner }
                        }
                    });
                    const { body } = await introductoryPriceEligibilityRestOperation.response;
                    const { isIntroductoryPriceEligible } = await body.json();
                    return isIntroductoryPriceEligible;
                } catch {
                    return false;
                }
            })();
            forEach(
                products,
                product => {
                    availableSubscriptions.push(
                        ...reduce(
                            product.subscriptionOfferDetails,
                            (subscriptionOffers, subscriptionOffer) => {
                                const subscription = planIdsSubscriptionsMap[
                                    subscriptionOffer.basePlanId
                                ];
                                if (!subscription) {
                                    return subscriptionOffers;
                                }

                                const pricingPhases = subscriptionOffer
                                    .pricingPhases
                                    .pricingPhaseList;
                                if (
                                    !isFreeEligible && pricingPhases.length > 1 ||
                                    (
                                        isFreeEligible &&
                                        pricingPhases.length === 1 &&
                                        filter(
                                            product.subscriptionOfferDetails,
                                            { basePlanId: subscriptionOffer.basePlanId }
                                        ).length > 1
                                    )
                                ) {
                                    return subscriptionOffers;
                                }

                                const subscriptionPricingPhase = find(
                                    pricingPhases,
                                    phase => phase.priceAmountMicros !== '0'
                                );
                                const freePricingPhase = find(
                                    pricingPhases,
                                    ['priceAmountMicros', '0']
                                );
                                const parsePricingPeriod = pricingPeriod => {
                                    const periodNumber = +pricingPeriod[1];
                                    const periodUnit = {
                                        D: 'DAY',
                                        W: 'WEEK',
                                        M: 'MONTH',
                                        Y: 'YEAR'
                                    }[pricingPeriod[2]];
                                    return {
                                        periodNumber,
                                        periodUnit
                                    };
                                };

                                subscriptionOffers.push({
                                    productId: product.productId,
                                    offerToken: subscriptionOffer.offerToken,
                                    ...(() => {
                                        const {
                                            periodNumber,
                                            periodUnit
                                        } = parsePricingPeriod(
                                            subscriptionPricingPhase.billingPeriod
                                        );
                                        return {
                                            subscriptionPeriodNumber: periodNumber,
                                            subscriptionPeriodUnit: periodUnit
                                        };
                                    })(),
                                    localizedPrice: subscriptionPricingPhase.formattedPrice,
                                    price: subscriptionPricingPhase.priceAmountMicros / 1000000,
                                    currency: subscriptionPricingPhase.priceCurrencyCode,
                                    ...isFreeEligible && freePricingPhase && (() => {
                                        const {
                                            periodNumber,
                                            periodUnit
                                        } = parsePricingPeriod(
                                            freePricingPhase.billingPeriod
                                        );
                                        return {
                                            freeTrialPeriodNumber: periodNumber,
                                            freeTrialPeriodUnit: periodUnit
                                        };
                                    })(),
                                    subscriptionType: subscription.subscriptionType
                                });
                                return subscriptionOffers;
                            },
                            []
                        )
                    );
                }
            );
        }

        dispatch(setIsLoading(false));
        dispatch(
            setAvailableSubscriptions(
                sortBy(
                    availableSubscriptions,
                    [
                        ({
                            subscriptionPeriodUnit,
                            subscriptionPeriodNumber
                        }) => `${{
                            DAY: 1,
                            WEEK: 2,
                            MONTH: 3,
                            YEAR: 4
                        }[subscriptionPeriodUnit]}${subscriptionPeriodNumber}`
                    ]
                )
            )
        );
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const resetAvailableSubscriptions = () => ({ setState }) => {
    setState(localInitialState);
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        fetchAvailableSubscriptions,
        resetAvailableSubscriptions
    },
    name: 'AvailableSubscriptions'
});

const useAvailableSubscriptionsState = createStateHook(
    Store,
    {
        selector: (
            {
                availableSubscriptions,
                isLoading,
                error
            }
        ) => ({
            availableSubscriptions,
            isLoading: isLoading || isLoading === null,
            error
        })
    }
);

const useAvailableSubscriptionsActions = createActionsHook(Store);

export const useAvailableSubscriptions = () => {
    const {
        availableSubscriptions,
        isLoading,
        error
    } = useAvailableSubscriptionsState();

    return {
        availableSubscriptions,
        isLoading,
        error
    };
};

export const useFetchAvailableSubscriptions = () => {
    const [{ appVersion }] = useAppInfo();
    const { fetchAvailableSubscriptions } = useAvailableSubscriptionsActions();

    return useMemo(
        () => fetchAvailableSubscriptions({ appVersion }),
        [appVersion, fetchAvailableSubscriptions]
    );
};

export const useResetAvailableSubscriptions = () => {
    const { resetAvailableSubscriptions } = useAvailableSubscriptionsActions();
    return resetAvailableSubscriptions;
};
