import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncHelpCenter = lazyForPaint(
    () => import(/* webpackChunkName: "async-help-center" */ './index')
        .then(({ HelpCenter }) => HelpCenter)
);

export const HelpCenter = () => {
    di(AsyncHelpCenter, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncHelpCenter />
        </LazySuspense>
    );
};
