const constants = {
    manifest: {
        name: 'Acrobacy: Training Journal',
        version: '1.0.0'
    },
    appOwnership: null
};

export const AppOwnership = {
    Standalone: 'standalone',
    Expo: 'expo',
    Guest: 'guest'
};

export default constants;
