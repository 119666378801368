import React from 'react';
import { Home } from '@pole-journal-web/home-screen/src/async';
import { TermsAndConditions } from '@pole-journal-web/toc-screen/src/async';
import { PrivacyPolicy } from '@pole-journal-web/privacy-policy-screen/src/async';
import { RequestDeleteAccount } from '@pole-journal-web/request-delete-account-screen/src/async';
import { OnlineLibraryGuide } from '@pole-journal-web/online-library-guide-screen/src/async';
import { SubscriptionPlans } from '@pole-journal-web/subscription-plans-screen/src/async';
import { HelpCenter } from '@pole-journal-web/help-center-screen/src/async';
import { Redirect } from '@pole-journal-web/redirect';
import { Contact } from '@pole-journal-web/contact-screen/src/async';
import { OnlineLibraryMoves } from '@pole-journal-web/online-library-moves-screen/src/async';
import { OnlineLibraryMove } from '@pole-journal-web/online-library-move-screen/src/async';
import { Seo, noCache } from '@pole-journal-web/seo';
import { di } from 'react-magnetic-di';

const getAppRoutes = ({
    Home,
    TermsAndConditions,
    PrivacyPolicy,
    RequestDeleteAccount,
    Contact,
    Seo,
    OnlineLibraryGuide,
    OnlineLibraryMoves,
    OnlineLibraryMove,
    SubscriptionPlans,
    Redirect
}) => [
    {
        name: 'terms-and-conditions',
        path: '/terms-and-conditions',
        exact: true,
        component: () => (
            <>
                <Seo title='Terms & Conditions' />
                <TermsAndConditions />
            </>
        ),
        resources: []
    },
    {
        name: 'privacy-policy',
        path: '/privacy-policy',
        exact: true,
        component: () => (
            <>
                <Seo title='Privacy' />
                <PrivacyPolicy />
            </>
        ),
        resources: []
    },
    {
        name: 'request-delete-account',
        path: '/request-delete-account',
        exact: true,
        component: () => (
            <>
                <Seo title='Request Account Removal' />
                <RequestDeleteAccount />
            </>
        ),
        resources: []
    },
    {
        name: 'online-library-guide',
        path: '/online-library-guide/:section?',
        exact: true,
        component: () => (
            <>
                <Seo title='Online Library Guide' />
                <OnlineLibraryGuide />
            </>
        ),
        resources: []
    },
    {
        name: 'help-center',
        path: '/help-center',
        exact: true,
        component: () => (
            <>
                <Seo title='Info Hub' />
                <HelpCenter />
            </>
        ),
        resources: []
    },
    {
        name: 'subscription-plans',
        path: '/subscription-plans',
        exact: true,
        component: () => (
            <>
                <Seo title='Subscriptions' />
                <SubscriptionPlans />
            </>
        ),
        resources: []
    },
    {
        name: 'online-library-moves',
        path: '/online-library-moves',
        exact: true,
        component: () => (
            <>
                <Seo title='Online Library Moves' />
                <OnlineLibraryMoves />
            </>
        ),
        resources: []
    },
    {
        name: 'online-library-move',
        path: '/online-library-move/:id',
        exact: true,
        component: () => (
            <>
                <Seo title='Online Library Move' />
                <OnlineLibraryMove />
            </>
        ),
        resources: []
    },
    {
        name: 'contact',
        path: '/contact',
        exact: true,
        component: () => (
            <>
                <Seo title='Contact' />
                <Contact />
            </>
        ),
        resources: []
    },
    {
        name: 'redirect',
        path: '/go/:page',
        exact: true,
        component: () => (
            <>
                <Seo meta={[...noCache]} />
                <Redirect />
            </>
        ),
        resources: []
    },
    // If route is not found, redirect to home
    {
        name: 'home',
        path: '/',
        component: () => (
            <>
                <Seo title='Home' />
                <Home />
            </>
        ),
        resources: []
    }
];

export const useAppRoutes = () => {
    di(
        Contact,
        Home,
        OnlineLibraryGuide,
        OnlineLibraryMove,
        OnlineLibraryMoves,
        PrivacyPolicy,
        Redirect,
        RequestDeleteAccount,
        Seo,
        SubscriptionPlans,
        TermsAndConditions
    );

    return getAppRoutes({
        Home,
        TermsAndConditions,
        PrivacyPolicy,
        RequestDeleteAccount,
        Contact,
        Seo,
        OnlineLibraryGuide,
        OnlineLibraryMoves,
        OnlineLibraryMove,
        SubscriptionPlans,
        Redirect
    });
};
