import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';

const MOVES_KEY = 'pole-journal.aws-amplify.datastore.moves';
const MOVE_TIMELINES_KEY = 'pole-journal.aws-amplify.datastore.move-timelines';
const TIMELINE_MEDIAS_KEY = 'pole-journal.aws-amplify.datastore.timeline-medias';
const TIMELINE_INSTRUCTORS_KEY = 'pole-journal.aws-amplify.datastore.timeline-instructors';
const MOVE_CUSTOM_LEVELS_KEY = 'pole-journal.aws-amplify.datastore.move-custom-levels';
const MOVE_TIMELINE_GEAR_KEY = 'pole-journal.aws-amplify.datastore.move-timeline-gear';
const SESSIONS_KEY = 'pole-journal.aws-amplify.datastore.sessions';
const ONLINE_LIBRARY_USER_SUBMISSIONS_KEY = 'pole-journal.aws-amplify.datastore.online-library-user-submissions';

let cacheStorage = null;
let retrievedCache = null;

export const initCache = ({
    chache: {
        setItem,
        getItem,
        removeItem
    }
}) => {
    retrievedCache = {};
    const cacheModels = [
        { name: 'Moves', key: MOVES_KEY, defaultValue: [] },
        { name: 'MoveTimelines', key: MOVE_TIMELINES_KEY, defaultValue: [] },
        { name: 'TimelineMedias', key: TIMELINE_MEDIAS_KEY, defaultValue: [] },
        { name: 'TimelineInstructors', key: TIMELINE_INSTRUCTORS_KEY, defaultValue: [] },
        { name: 'MoveCustomLevels', key: MOVE_CUSTOM_LEVELS_KEY, defaultValue: [] },
        { name: 'MoveTimelineGear', key: MOVE_TIMELINE_GEAR_KEY, defaultValue: [] },
        { name: 'Sessions', key: SESSIONS_KEY, defaultValue: [] },
        { name: 'OnlineLibraryUserSubmissions', key: ONLINE_LIBRARY_USER_SUBMISSIONS_KEY, defaultValue: [] }
    ];
    cacheStorage = reduce(
        cacheModels,
        (cache, { name, key, defaultValue }) => {
            cache[`set${name}`] = async models => {
                try {
                    await setItem(key, JSON.stringify(models));
                } catch {
                    // error saving value
                }
            };

            cache[`get${name}`] = async () => {
                try {
                    const models = await getItem(key);
                    return models ? JSON.parse(models) : defaultValue;
                } catch {
                    return defaultValue;
                }
            };

            cache[`is${name}Retrieved`] = () => retrievedCache[key] ?? false;

            cache[`set${name}IsRetrieved`] = () => {
                retrievedCache[key] = true;
            };

            return cache;
        },
        {}
    );

    cacheStorage.clearCache = () => {
        forEach(cacheModels, ({ key }) => {
            removeItem(key);
        });
    };
};

export const getCache = () => {
    if (cacheStorage === null) {
        throw new Error('Datastore chache is not initialized');
    }

    return cacheStorage;
};

export const clearCache = () => {
    retrievedCache = {};
    getCache().clearCache();
};
