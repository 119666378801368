import React from 'react';
import {
    Redirect as RouterRedirect,
    usePathParam
} from 'react-resource-router';
import {
    useSendActiveScreenEvent,
    ScreenName
} from '@pole-journal-web/app-analytics-bridge';

const redirectMap = {
    'play-store': {
        url: 'https://play.google.com/store/apps/details?id=io.codexplorer.polejournal',
        screenName: ScreenName.goPlayStore
    },
    'app-store': {
        url: 'https://apps.apple.com/us/app/pole-aerial-fitness-tracker/id1625021831',
        screenName: ScreenName.goAppStore
    },
    insta: {
        url: 'https://www.instagram.com/acrobacy.app',
        screenName: ScreenName.goInsta
    },
    fb: {
        url: 'https://www.facebook.com/acrobacy',
        screenName: ScreenName.goFb
    },
    tiktok: {
        url: 'https://www.tiktok.com/@acrobacy.app',
        screenName: ScreenName.goTikTok
    },
    reddit: {
        url: 'https://www.reddit.com/r/Acrobacy',
        screenName: ScreenName.goReddit
    },
    youtube: {
        url: 'https://www.youtube.com/@acrobacy',
        screenName: ScreenName.goYouTube
    },
    threads: {
        url: 'https://www.threads.net/@acrobacy.app',
        screenName: ScreenName.goThreads
    }
};

export const Redirect = () => {
    const [page] = usePathParam('page');
    const { screenName, url } = redirectMap[page] ?? {
        screenName: ScreenName.goNotFound,
        url: '/'
    };

    useSendActiveScreenEvent({ screenName });

    return (
        <RouterRedirect to={url} />
    );
};
