import React from 'react';
import { di } from 'react-magnetic-di';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as FluentUiThemeProvider, createTheme } from '@fluentui/react';
import pSBC from 'shade-blend-color';
import { ReactComponent as DancePoleIcon } from './assets/icons/dance-pole.svg';
import { ReactComponent as LinkIcon } from './assets/icons/link.svg';

const fluentUiTheme = createTheme({
    palette: {
        themePrimary: '#039be5',
        themeLighterAlt: '#f4fbfe',
        themeLighter: '#d3eefb',
        themeLight: '#aedff7',
        themeTertiary: '#61c0f0',
        themeSecondary: '#1ea5e9',
        themeDarkAlt: '#028acf',
        themeDark: '#0275ae',
        themeDarker: '#015681',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f5f5f5',
        neutralLight: '#e3e3e3',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff'
    }
});

const iconsMap = {
    'dance-pole': ({ size, color }) => (
        <DancePoleIcon
            width={size}
            height={size}
            fill={color}
        />
    ),
    link: ({ size, color }) => (
        <LinkIcon
            width={size}
            height={size}
            fill={color}
        />
    )
};

const Icon = props => {
    const { name } = props;
    const Icon = iconsMap[name];
    return Icon ? (
        <Icon {...props} />
    ) : (
        null
    );
};

const styledTheme = {
    colors: {
        primary: fluentUiTheme.palette.themePrimary,
        primaryLight: pSBC(0.7, fluentUiTheme.palette.themePrimary),
        onPrimary: fluentUiTheme.palette.white,
        primaryAlt: fluentUiTheme.palette.themeLighterAlt,
        primaryAltHover: fluentUiTheme.palette.themeLighter,
        neutralLighter: fluentUiTheme.palette.neutralLighter,
        neutralLight: fluentUiTheme.palette.neutralLight,
        primaryHover: fluentUiTheme.palette.themeDarker,
        background: fluentUiTheme.palette.white,
        text: fluentUiTheme.palette.neutralPrimary,
        warning: '#cfac00',
        error: '#d40000'
    },
    style: {
        imgBlackToPrimaryFilter: 'invert(48%) sepia(88%) saturate(2912%) hue-rotate(171deg) brightness(96%) contrast(98%)',
        imgBlackToWhiteFilter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(14deg) brightness(101%) contrast(101%)'
    },
    fonts: {
        tiny: fluentUiTheme.fonts.tiny,
        xSmall: fluentUiTheme.fonts.xSmall,
        small: fluentUiTheme.fonts.small,
        smallPlus: fluentUiTheme.fonts.smallPlus,
        medium: fluentUiTheme.fonts.medium,
        mediumPlus: fluentUiTheme.fonts.mediumPlus,
        large: fluentUiTheme.fonts.large,
        xLarge: fluentUiTheme.fonts.xLarge,
        xLargePlus: fluentUiTheme.fonts.xLargePlus,
        xxLarge: fluentUiTheme.fonts.xxLarge,
        xxLargePlus: fluentUiTheme.fonts.xxLargePlus,
        superLarge: fluentUiTheme.fonts.superLarge,
        mega: fluentUiTheme.fonts.mega
    },
    Icon
};

export const useTheme = () => styledTheme;

export const AppThemeProvider = ({ children }) => {
    di(FluentUiThemeProvider, StyledThemeProvider);

    return (
        <StyledThemeProvider theme={styledTheme}>
            <FluentUiThemeProvider theme={fluentUiTheme}>
                {children}
            </FluentUiThemeProvider>
        </StyledThemeProvider>
    );
};
