import assign from 'lodash/assign';

const module = {};

export const getModule = () => module;

export const initUserSubscriptions = ({
    restApiName,
    SubscriptionPlatform,
    graphql,
    getOwner,
    useFetchSubscriptionDetails,
    useIsUserFetched,
    useGetSubscriptionType,
    extraAvailableSubscriptionFields
}) => {
    assign(module, {
        restApiName,
        SubscriptionPlatform,
        graphql,
        getOwner,
        useFetchSubscriptionDetails,
        useIsUserFetched,
        useGetSubscriptionType,
        extraAvailableSubscriptionFields
    });
};
