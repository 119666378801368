import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncRequestDeleteAccount = lazyForPaint(
    () => import(/* webpackChunkName: "async-request-delete-account" */ './index')
        .then(({ RequestDeleteAccount }) => RequestDeleteAccount)
);

export const RequestDeleteAccount = () => {
    di(AsyncRequestDeleteAccount, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncRequestDeleteAccount />
        </LazySuspense>
    );
};
