import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import { v4 as uuid } from 'uuid';
import {
    Shimmer,
    ShimmerElementType
} from '@fluentui/react';
import { usePrevious } from '@codexporer.io/react-hooks';
import imageErrorPlaceholder from './assets/image-error-placeholder.png';
import videoErrorPlaceholder from './assets/video-error-placeholder.png';
import {
    Container,
    Image,
    VideoWrapper,
    Video,
    OverlayContainer
} from './styled';

const RETRY_COUNT = 6;
const RETRY_DELAY_MS = 5000;
const TIMEOUT_ERROR_MS = 30000;

export const ProgressiveMedia = ({
    width,
    height,
    shouldDisplaySkeleton,
    placeholderSource = null,
    errorPlaceholderSource = null,
    thumbnailSource,
    source,
    className,
    shouldBlurThumbnail = false,
    overlay,
    showOverlayOnLoad = false,
    isVideo = false,
    ...props
}) => {
    const [placeholderOpacity, setPlaceholderOpacity] = useState(0);
    const [placeholderRenderKey, setPlaceholderRenderKey] = useState(uuid());
    const [thumbnailOpacity, setThumbnailOpacity] = useState(0);
    const [thumbnailRenderKey, setThumbnailRenderKey] = useState(uuid());
    const [imageOpacity, setImageOpacity] = useState(0);
    const [errorOpacity, setErrorOpacity] = useState(0);
    const [imageRenderKey, setImageRenderKey] = useState(uuid());
    const [canShowLoading, setCanShowLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [retryTimeoutReference, setRetryTimeoutReference] = useState(null);
    const [isError, setIsError] = useState(false);

    const errorPlaceholderImage = errorPlaceholderSource?.uri ?? (
        isVideo ? videoErrorPlaceholder : imageErrorPlaceholder
    );

    // Reset placeholder when placeholder source is changed
    const previousPlaceholderSourceUri = usePrevious(placeholderSource?.uri ?? undefined);
    useEffect(() => {
        const shouldRerenderPlaceholder = previousPlaceholderSourceUri !== undefined &&
            previousPlaceholderSourceUri !== (placeholderSource?.uri ?? undefined);
        if (!shouldRerenderPlaceholder) {
            return;
        }

        setPlaceholderOpacity(0);
        setPlaceholderRenderKey(uuid());
    }, [
        placeholderSource?.uri,
        previousPlaceholderSourceUri,
        setPlaceholderOpacity
    ]);

    // Reset thumbnail when thumbnail source is changed
    const previousThumbnailSourceUri = usePrevious(thumbnailSource?.uri ?? undefined);
    useEffect(() => {
        const shouldRerenderThumbnail = previousThumbnailSourceUri !== undefined &&
            previousThumbnailSourceUri !== (thumbnailSource?.uri ?? undefined);
        if (!shouldRerenderThumbnail) {
            return;
        }

        setThumbnailOpacity(0);
        setThumbnailRenderKey(uuid());
        setIsLoaded(false);
    }, [
        thumbnailSource?.uri,
        previousThumbnailSourceUri,
        setThumbnailOpacity
    ]);

    // Reset media when source is changed
    const previousImageSourceUri = usePrevious(source?.uri ?? undefined);
    useEffect(() => {
        const shouldRerenderImage = previousImageSourceUri !== undefined &&
            previousImageSourceUri !== (source?.uri ?? undefined);
        if (!shouldRerenderImage) {
            return;
        }

        setImageOpacity(0);
        setImageRenderKey(uuid());
        setRetryCount(0);
        setIsError(false);
        setIsLoaded(false);
        setIsImageLoaded(false);
    }, [
        source?.uri,
        previousImageSourceUri,
        setImageOpacity
    ]);

    // Hide loading when thumbnail/media has loaded or when media has error
    useEffect(() => {
        setCanShowLoading(!isLoaded && !isError);
    }, [isLoaded, isError]);

    // Display error if media does't load in TIMEOUT_ERROR_MS and did not retry
    const reportErrorDependenciesRef = useRef();
    reportErrorDependenciesRef.current = {
        isImageLoaded,
        isError,
        retryCount,
        source
    };
    useEffect(() => {
        const reportErrorTimeoutReference = setTimeout(() => {
            const {
                isImageLoaded,
                isError,
                retryCount,
                source
            } = reportErrorDependenciesRef.current;
            if (source && retryCount === 0 && !isImageLoaded && !isError) {
                setIsError(true);
            }
        }, TIMEOUT_ERROR_MS);

        return () => {
            clearTimeout(reportErrorTimeoutReference);
        };
    }, [source]);

    // Reset error if media is loaded
    useEffect(() => {
        if (isImageLoaded && isError) {
            setIsError(false);
        }
    }, [isImageLoaded, isError]);

    const onPlaceholderLoad = () => {
        if (isLoaded) {
            return;
        }

        setPlaceholderOpacity(1);
    };

    const onThumbnailLoad = () => {
        if (isImageLoaded) {
            return;
        }

        setThumbnailOpacity(1);
        setPlaceholderOpacity(0);
        setIsLoaded(true);
    };

    const onImageLoad = () => {
        setImageOpacity(1);
        setThumbnailOpacity(0);
        setPlaceholderOpacity(0);
        setIsLoaded(true);
        setIsImageLoaded(true);
        setRetryCount(0);
    };

    const onPlaceholderError = () => {
        setPlaceholderRenderKey(uuid());
    };

    const onThumbnailError = () => {
        setThumbnailRenderKey(uuid());
    };

    const onImageError = error => {
        // eslint-disable-next-line no-console
        console.error('onImageError', error);
        if (retryCount < RETRY_COUNT) {
            setRetryCount(retryCount + 1);
            const timeoutReference = setTimeout(
                () => {
                    setRetryTimeoutReference(null);
                    setImageRenderKey(uuid());
                },
                RETRY_DELAY_MS
            );
            setRetryTimeoutReference(timeoutReference);
        } else {
            setIsError(true);
        }
    };

    const onErrorPlaceholderLoad = () => {
        setImageOpacity(0);
        setThumbnailOpacity(0);
        setPlaceholderOpacity(0);
        setErrorOpacity(1);
        setIsLoaded(true);
        setRetryCount(0);
        setIsImageLoaded(false);
    };

    useEffect(() => () => {
        if (retryTimeoutReference !== null) {
            clearTimeout(retryTimeoutReference);
        }
    }, [retryTimeoutReference]);

    return (
        <Container
            // eslint-disable-next-line react/forbid-component-props
            className={className}
        >
            {placeholderSource && (
                <Image
                    key={placeholderRenderKey}
                    {...props}
                    src={placeholderSource.uri}
                    onLoad={onPlaceholderLoad}
                    onError={onPlaceholderError}
                    width={width}
                    height={height}
                    opacity={placeholderOpacity}
                />
            )}
            {canShowLoading && shouldDisplaySkeleton && (
                <OverlayContainer>
                    <Shimmer
                        width={width}
                        shimmerElements={[{ type: ShimmerElementType.line, height }]}
                    />
                </OverlayContainer>
            )}
            {thumbnailSource && (
                <Image
                    key={thumbnailRenderKey}
                    {...props}
                    src={thumbnailSource.uri}
                    onLoad={onThumbnailLoad}
                    onError={onThumbnailError}
                    width={width}
                    height={height}
                    opacity={thumbnailOpacity}
                    blurRadius={shouldBlurThumbnail ? 3 : 0}
                />
            )}
            {source && (
                isVideo ? (
                    <VideoWrapper opacity={imageOpacity}>
                        <Video
                            key={`${imageRenderKey}-staticVideo`}
                            url={source.uri}
                            onReady={onImageLoad}
                            onError={onImageError}
                            width={width}
                            height={height}
                            config={{
                                youtube: {
                                    playerVars: {
                                        controls: 1
                                    }
                                },
                                facebook: {
                                    attributes: {
                                        'data-width': width,
                                        'data-allowfullscreen': true,
                                        'data-controls': true
                                    }
                                }
                            }}
                            controls
                            playsinline
                        />
                    </VideoWrapper>
                ) : (
                    <Image
                        key={`${imageRenderKey}-staticImage`}
                        {...props}
                        src={source.uri}
                        onLoad={onImageLoad}
                        onError={onImageError}
                        width={width}
                        height={height}
                        opacity={imageOpacity}
                    />
                )
            )}
            {isError && (
                <Image
                    {...props}
                    src={errorPlaceholderImage}
                    onLoad={onErrorPlaceholderLoad}
                    width={width}
                    height={height}
                    opacity={errorOpacity}
                />
            )}
            {(!showOverlayOnLoad || showOverlayOnLoad && isLoaded) && overlay && (
                <OverlayContainer>
                    {overlay}
                </OverlayContainer>
            )}
        </Container>
    );
};
