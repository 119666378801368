import { useEffect, useState } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import noop from 'lodash/noop';
import remove from 'lodash/remove';
import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { StoreType, saveFile } from '../storage';
import { Move } from '../models';

const localInitialState = {
    moves: [],
    isLoading: null,
    error: null,
    didInitialLoad: false
};

const moveFieldsQueryFragment = `
    fragment MoveFields on Move {
        id
        discipline
        name
        altNames
        level
        customLevelId
        customLevel {
            id
            name
            _deleted
        }
        link
        links {
            link
            displayText
        }
        status
        description
        imageId
        coverVideoId
        coverVideoUrl
        updatedFromOnlineLibraryMoveId
        updatedFromOnlineLibraryMoveVersion
        updatedFromOnlineLibraryMove {
            id
            _version
            _deleted
        }
        _version
        _deleted
    }
`;

const getMovesByOwnerQuery = `
    ${moveFieldsQueryFragment}
    query QueryMovesByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: movesByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...MoveFields
            }
            nextToken
        }
    }
`;

const getSaveMoveQuery = ({ isUpdate }) => `
    ${moveFieldsQueryFragment}
    mutation SaveMove
    (
        $discipline: MoveDiscipline!
        $name: String!
        $altNames: [String!]
        $level: MoveLevel!
        $customLevelId: ID
        $link: String
        $links: [LinkInput!]
        $status: MoveStatus!
        $description: String
        $imageId: String
        $coverVideoId: String
        $coverVideoUrl: String
        $updatedFromOnlineLibraryMoveId: ID
        $updatedFromOnlineLibraryMoveVersion: Int
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateMove' : 'createMove'}(
            input: {
                discipline: $discipline
                name: $name
                altNames: $altNames
                level: $level
                customLevelId: $customLevelId
                link: $link
                links: $links
                status: $status
                description: $description
                imageId: $imageId
                coverVideoId: $coverVideoId
                coverVideoUrl: $coverVideoUrl
                updatedFromOnlineLibraryMoveId: $updatedFromOnlineLibraryMoveId
                updatedFromOnlineLibraryMoveVersion: $updatedFromOnlineLibraryMoveVersion
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...MoveFields
        }
    }
`;

const deleteMoveQuery = `
    ${moveFieldsQueryFragment}
    mutation DeleteMove
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteMove(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...MoveFields
        }
    }
`;

const selectMovesByStatus = memoize(
    (moves, status) => status ? filter(
        moves,
        { status }
    ) : moves,
    (...args) => JSON.stringify(args)
);

const clearSelectors = () => {
    selectMovesByStatus.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isMovesRetrieved()) {
        return;
    }

    getCache().setMovesIsRetrieved();
    const moves = await getCache().getMoves();
    clearSelectors();
    setState({ moves });
};

const setMoves = moves => ({ setState }) => {
    clearSelectors();
    setState({ moves });
    getCache().setMoves(moves);
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchMoves = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const moves = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getMovesByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        moves.push(
            ...filter(
                map(
                    result.data.result?.items,
                    ({
                        customLevelId,
                        customLevel,
                        updatedFromOnlineLibraryMove,
                        ...move
                    }) => ({
                        ...move,
                        customLevelId: (!customLevel || customLevel._deleted) ?
                            null :
                            customLevelId,
                        customLevel: (!customLevel || customLevel._deleted) ?
                            null :
                            { ...customLevel },
                        updatedFromOnlineLibraryMove: (
                            (
                                !updatedFromOnlineLibraryMove ||
                                updatedFromOnlineLibraryMove._deleted
                            ) ?
                                null :
                                { ...updatedFromOnlineLibraryMove }
                        )
                    })
                ),
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setMoves(moves));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveMove = ({
    move,
    movePreviousState,
    coverImageUrl,
    coverVideoUrl
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!movePreviousState;
    const savedMove = (await graphql({
        query: getSaveMoveQuery({ isUpdate }),
        variables: { ...move }
    })).data.result;

    const {
        imageId: previousCoverImageId,
        coverVideoId: previousCoverVideoId
    } = movePreviousState ?? {};
    const {
        imageId: coverImageId,
        coverVideoId
    } = savedMove;
    await Promise.all([
        previousCoverImageId !== coverImageId && coverImageId && coverImageUrl ?
            saveFile({
                id: coverImageId,
                url: coverImageUrl
            }).catch(noop) :
            Promise.resolve(),
        previousCoverVideoId !== coverVideoId && coverVideoId && coverVideoUrl ?
            saveFile({
                id: coverVideoId,
                url: coverVideoUrl,
                storeType: StoreType.userMoveCoverVideo
            }).catch(noop) :
            Promise.resolve()
    ]);

    const moves = [...getState().moves];
    if (isUpdate) {
        remove(moves, ({ id }) => id === savedMove.id);
        const {
            name: previousName,
            discipline: previousDiscipline
        } = movePreviousState;
        const { name, discipline } = savedMove;
        if (previousName !== name || previousDiscipline !== discipline) {
            dispatch(actions.getLinkedStore('moveTimelines')).onMoveSaved(savedMove);
        }
    }

    moves.push(savedMove);
    dispatch(setMoves(moves));
    return savedMove;
};

const deleteMove = ({
    move: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedMove = (await graphql({
        query: deleteMoveQuery,
        variables: { id, _version }
    })).data.result;

    const moves = [...getState().moves];
    remove(moves, ({ id }) => id === deletedMove.id);
    dispatch(actions.getLinkedStore('moveTimelines')).onMoveDeleted(deletedMove);
    dispatch(actions.getLinkedStore('sessions')).onMoveDeleted(deletedMove);
    dispatch(setMoves(moves));
    return deletedMove;
};

const deleteAllMoves = () => async ({ dispatch }) => {
    await DataStore.delete(Move, Predicates.ALL);
    dispatch(actions.getLinkedStore('moveTimelines')).onAllMovesDeleted();
    dispatch(actions.getLinkedStore('sessions')).onAllMovesDeleted();
    dispatch(setMoves([]));
};

const onMoveCustomLevelSaved = ({ id, name }) => ({ getState, dispatch }) => {
    const moves = [...getState().moves];
    let isChanged = false;
    forEach(moves, move => {
        if (move.customLevelId === id && move.customLevel?.name) {
            isChanged = true;
            move.customLevel.name = name;
        }
    });

    isChanged && dispatch(setMoves(moves));
};

const onMoveCustomLevelDeleted = ({ id }) => ({ getState, dispatch }) => {
    const moves = [...getState().moves];
    let isChanged = false;
    forEach(moves, move => {
        if (move.customLevelId === id) {
            isChanged = true;
            move.customLevelId = null;
            move.customLevel = null;
        }
    });
    isChanged && dispatch(setMoves(moves));
};

const onAllMoveCustomLevelsDeleted = () => ({ getState, dispatch }) => {
    const moves = [...getState().moves];
    let isChanged = false;
    forEach(moves, move => {
        if (move.customLevelId) {
            isChanged = true;
            move.customLevelId = null;
            move.customLevel = null;
        }
    });
    isChanged && dispatch(setMoves(moves));
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchMoves,
        onInitialLoad,
        saveMove,
        deleteMove,
        deleteAllMoves,
        onMoveCustomLevelSaved,
        onMoveCustomLevelDeleted,
        onAllMoveCustomLevelsDeleted
    },
    name: 'Moves'
});

const useMovesState = createStateHook(
    Store,
    {
        selector: (
            {
                moves,
                isLoading,
                error,
                didInitialLoad
            },
            { status }
        ) => ({
            moves: selectMovesByStatus(moves, status),
            isLoading: isLoading || isLoading === null,
            error,
            didInitialLoad
        })
    }
);

export const useMovesActions = createActionsHook(Store);

export const useMoves = ({ status } = {}) => {
    const { fetchMoves, onInitialLoad } = useMovesActions();
    const {
        moves,
        isLoading,
        error,
        didInitialLoad
    } = useMovesState({ status });
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (!didInitialLoad || error && !didFetch) {
            fetchMoves();
            onInitialLoad();
            setDidFetch(true);
        }
    }, [
        didFetch,
        didInitialLoad,
        error,
        fetchMoves,
        onInitialLoad
    ]);

    return {
        moves,
        isLoading,
        error
    };
};

export const useMovesCount = () => {
    const {
        moves,
        isLoading,
        error
    } = useMoves();

    if (isLoading || error || !moves) {
        return undefined;
    }

    return moves.length;
};

export const useMove = ({ id }) => {
    const {
        moves,
        isLoading,
        error
    } = useMoves();

    return {
        move: find(moves, { id }),
        isLoading,
        error
    };
};

export const useFetchMoves = () => {
    const { fetchMoves } = useMovesActions();
    return fetchMoves;
};

export const useSaveMove = () => {
    const { saveMove } = useMovesActions();
    return saveMove;
};

export const useDeleteMove = () => {
    const { deleteMove } = useMovesActions();
    return deleteMove;
};

export const useDeleteAllMoves = () => {
    const { deleteAllMoves } = useMovesActions();
    return deleteAllMoves;
};
