import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import find from 'lodash/find';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import { graphql } from './graphql';
import { awsConfig } from '../aws.config';

export const RemoteConfigProperty = {
    /**
     * Version before and including on which force update screen should be shown at the app start on android
     */
    ANDROID_FORCE_UPDATE_VERSION: 'android_force_update_version',
    /**
     * Version before and including on which force update screen should be shown at the app start on ios
     */
    IOS_FORCE_UPDATE_VERSION: 'ios_force_update_version',
    /**
     * If NEW label should be shown on sidebar Library item
     */
    SHOULD_DISPLAY_SIDEBAR_NEW_ON_LIBRARY: 'should_display_sidebar_new_on_library',
    /**
     * If NEW label should be shown on sidebar Premium item
     */
    SHOULD_DISPLAY_SIDEBAR_NEW_ON_PREMIUM: 'should_display_sidebar_new_on_premium',
    /**
     * Number of minutes from which Unilimited Moves promotion screen should be shown, after the app start on Android (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_MINUTES_FROM_START_ANDROID: 'unlimited_moves_promotion_minutes_from_start_android',
    /**
     * Number of minutes from which Unilimited Moves promotion screen should be shown, after the app start on iOS (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_MINUTES_FROM_START_IOS: 'unlimited_moves_promotion_minutes_from_start_ios',
    /**
     * Min hours passed from the app install and first view of Unilimited Moves promotion screen on Android (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_FIRST_SHOWN_HOURS_ANDROID: 'unlimited_moves_promotion_first_shown_hours_android',
    /**
     * Min hours passed from the app install and first view of Unilimited Moves promotion screen on iOS (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_FIRST_SHOWN_HOURS_IOS: 'unlimited_moves_promotion_first_shown_hours_ios',
    /**
     * Min days passed from previous view of Unilimited Moves promotion screen and next display on Android (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_NEXT_SHOWN_DAYS_ANDROID: 'unlimited_moves_promotion_next_shown_days_android',
    /**
     * Min days passed from previous view of Unilimited Moves promotion screen and next display on iOS (0 to disable)
     */
    UNLIMITED_MOVES_PROMOTION_NEXT_SHOWN_DAYS_IOS: 'unlimited_moves_promotion_next_shown_days_ios'
};

const getString = value => value;

const getNumber = value => {
    if (!value) {
        return null;
    }

    return +value;
};

const getBoolean = value => (
    value === 'true' ?
        true :
        value === 'false' ?
            false :
            undefined
);

const remoteConfigPropertyConfigurationsMap = {
    [RemoteConfigProperty.ANDROID_FORCE_UPDATE_VERSION]: {
        defaultValue: '0',
        getValue: getString
    },
    [RemoteConfigProperty.IOS_FORCE_UPDATE_VERSION]: {
        defaultValue: '0',
        getValue: getString
    },
    [RemoteConfigProperty.SHOULD_DISPLAY_SIDEBAR_NEW_ON_LIBRARY]: {
        defaultValue: false,
        getValue: getBoolean
    },
    [RemoteConfigProperty.SHOULD_DISPLAY_SIDEBAR_NEW_ON_PREMIUM]: {
        defaultValue: false,
        getValue: getBoolean
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_MINUTES_FROM_START_ANDROID]: {
        defaultValue: 0,
        getValue: getNumber
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_MINUTES_FROM_START_IOS]: {
        defaultValue: 0,
        getValue: getNumber
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_FIRST_SHOWN_HOURS_ANDROID]: {
        defaultValue: 0,
        getValue: getNumber
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_FIRST_SHOWN_HOURS_IOS]: {
        defaultValue: 0,
        getValue: getNumber
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_NEXT_SHOWN_DAYS_ANDROID]: {
        defaultValue: 0,
        getValue: getNumber
    },
    [RemoteConfigProperty.UNLIMITED_MOVES_PROMOTION_NEXT_SHOWN_DAYS_IOS]: {
        defaultValue: 0,
        getValue: getNumber
    }
};

const DEFAULT_VALUES = reduce(
    values(RemoteConfigProperty),
    (defaultValues, key) => {
        defaultValues[key] = remoteConfigPropertyConfigurationsMap[key].defaultValue;
        return defaultValues;
    },
    {}
);

const transformConfigValues = config => reduce(
    values(RemoteConfigProperty),
    (values, key) => {
        const {
            getValue,
            defaultValue
        } = remoteConfigPropertyConfigurationsMap[key];
        values[key] = getValue(find(config, { name: key })?.value) ?? defaultValue;
        return values;
    },
    {}
);

const localInitialState = {
    config: DEFAULT_VALUES,
    isLoading: null,
    error: null
};

const appRemoteConfigFieldsQueryFragment = `
    fragment AppRemoteConfigFields on AppRemoteConfig {
        id
        name
        value
        _lastChangedAt
        _version
        _deleted
    }
`;

const listAppRemoteConfigQuery = `
    ${appRemoteConfigFieldsQueryFragment}
    query ListAppRemoteConfigs(
        $limit: Int
        $nextToken: String
    ) {
        result: listAppRemoteConfigs(
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...AppRemoteConfigFields
            }
            nextToken
        }
    }
`;

const setConfig = config => ({ setState }) => {
    setState({ config });
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchConfig = () => async ({ getState, dispatch }) => {
    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        const config = [];
        const fetchData = async ({ nextToken }) => {
            const result = await graphql({
                query: listAppRemoteConfigQuery,
                variables: {
                    limit: 100,
                    nextToken
                },
                authToken: awsConfig.awsmobile.aws_appsync_apiKey,
                authMode: 'apiKey'
            });

            config.push(
                ...filter(
                    result.data.result?.items,
                    ({ _deleted }) => _deleted !== true
                )
            );

            nextToken = result.data.result?.nextToken;
            if (nextToken) {
                await fetchData({ nextToken });
            }
        };

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setConfig(transformConfigValues(config)));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
        dispatch(setConfig({ ...DEFAULT_VALUES }));
    }
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        fetchConfig
    },
    name: 'AppRemoteConfig'
});

const useAppRemoteConfigState = createStateHook(
    Store,
    {
        selector: (
            {
                config,
                isLoading,
                error
            }
        ) => ({
            config,
            isLoading: isLoading || isLoading === null,
            error
        })
    }
);

export const useAppRemoteConfigActions = createActionsHook(Store);

export const useAppRemoteConfig = () => {
    const { config } = useAppRemoteConfigState();
    return config;
};

export const useIsAppRemoteConfigInitialized = () => {
    const { isLoading } = useAppRemoteConfigState();
    return !isLoading;
};

export const useFetchAppRemoteConfig = () => {
    const { fetchConfig } = useAppRemoteConfigActions();
    return fetchConfig;
};
