import {
    createStore,
    createStateHook,
    createActionsHook
} from 'react-sweet-state';
import { requestSubscription as requestSubscriptionIAP } from 'react-native-iap';
import {
    initialState,
    actions
} from '@codexporer.io/expo-link-stores';
import { OS } from '@codexporer.io/expo-device';
import {
    APP_SNACKBAR_POSITION,
    APP_SNACKBAR_DURATION
} from '@codexporer.io/expo-app-snackbar';
import { post } from 'aws-amplify/api';
import { getModule } from './module';

const localInitialState = {
    isSubscriptionRequested: false
};

const requestSubscription = subscription => async ({
    getState,
    setState,
    dispatch
}) => {
    if (getState().isSubscriptionRequested) {
        return;
    }

    setState({ isSubscriptionRequested: true });
    dispatch(actions.getLinkedStore('loadingDialog')).show({
        message: 'Unlocking premium access...'
    });

    try {
        await requestSubscriptionIAP(subscription.offerToken ? {
            subscriptionOffers: [
                {
                    sku: subscription.productId,
                    offerToken: subscription.offerToken
                }
            ]
        } : { sku: subscription.productId });
    } catch {
        setState({ isSubscriptionRequested: false });
        dispatch(actions.getLinkedStore('loadingDialog')).hide();
        dispatch(actions.getLinkedStore('appSnackbar')).show({
            message: 'There was an error while requesting premium access.',
            duration: APP_SNACKBAR_DURATION.short,
            position: APP_SNACKBAR_POSITION.bottom
        });
    }
};

const cancelSubscriptionRequest = () => async ({
    getState,
    setState,
    dispatch
}) => {
    if (!getState().isSubscriptionRequested) {
        return;
    }

    setState({ isSubscriptionRequested: false });
    dispatch(actions.getLinkedStore('loadingDialog')).hide();
    dispatch(actions.getLinkedStore('appSnackbar')).show({
        message: 'Subscription request has been canceled.',
        duration: APP_SNACKBAR_DURATION.short,
        position: APP_SNACKBAR_POSITION.bottom
    });
};

const finalizeSubscriptionRequest = () => async ({
    getState,
    setState,
    dispatch
}) => {
    if (!getState().isSubscriptionRequested) {
        return;
    }

    setState({ isSubscriptionRequested: false });
    dispatch(actions.getLinkedStore('loadingDialog')).hide();
    dispatch(actions.getLinkedStore('appSnackbar')).show({
        message: 'Your premium access has been unlocked.',
        duration: APP_SNACKBAR_DURATION.short,
        position: APP_SNACKBAR_POSITION.bottom
    });
};

const subscribe = purchase => async ({
    getState,
    setState,
    dispatch
}) => {
    const {
        getOwner,
        restApiName,
        SubscriptionPlatform
    } = getModule();
    const { isSubscriptionRequested } = getState();
    const { owner } = await getOwner();
    try {
        const subscribeRestOperation = post({
            apiName: restApiName,
            path: '/user-subscription/subscribe',
            options: {
                body: {
                    owner,
                    receipt: OS.isIOS() ? purchase.transactionReceipt : (() => {
                        const androidPurchase = JSON.parse(purchase.transactionReceipt);
                        return JSON.stringify({
                            packageName: androidPurchase.packageName,
                            productId: androidPurchase.productId,
                            purchaseToken: androidPurchase.purchaseToken,
                            subscription: true
                        });
                    })(),
                    platform: OS.isIOS() ?
                        SubscriptionPlatform.APPLE_STORE :
                        SubscriptionPlatform.GOOGLE_PLAY
                }
            }
        });
        const { body } = await subscribeRestOperation.response;
        const { isSubscribed } = await body.json();
        return isSubscribed;
    } catch {
        if (isSubscriptionRequested) {
            setState({ isSubscriptionRequested: false });
            dispatch(actions.getLinkedStore('loadingDialog')).hide();
            dispatch(actions.getLinkedStore('appSnackbar')).show({
                message: 'There was an error while requesting premium access.',
                duration: APP_SNACKBAR_DURATION.short,
                position: APP_SNACKBAR_POSITION.bottom
            });
        }
    }

    return false;
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        requestSubscription,
        cancelSubscriptionRequest,
        finalizeSubscriptionRequest,
        subscribe
    },
    name: 'SubscribeFlow'
});

export const useSubscribeFlowState = createStateHook(
    Store,
    {
        selector: ({ isSubscriptionRequested }) => ({
            isSubscriptionRequested
        })
    }
);

export const useSubscribeFlowActions = createActionsHook(Store);
