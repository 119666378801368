import styled from 'styled-components';
import { ProgressiveMedia } from '@pole-journal-web/progressive-media';

export const AvatarRoot = styled.div`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    box-sizing: border-box;
    min-width: ${({ size }) => size}px;
    min-height: ${({ size }) => size}px;
    border-radius: ${({ size }) => Math.round(size / 2)}px;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ hasBorder, theme }) => `${hasBorder ? '2px' : 0} solid ${theme.colors.onPrimary}`};
`;

export const AvatarLetter = styled.span`
    font-size: ${({ size }) => Math.round(size * 0.6)}px;
    color: ${({ theme }) => theme.colors.onPrimary};
    text-transform: uppercase;
    font-weight: bold;
    font-family: sans-serif;
`;

export const ProfileImage = styled(ProgressiveMedia)`
    flex: 1;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: ${({ width }) => Math.round(width / 2)}px;
    background-color: ${({ theme }) => theme.colors.skeletonLight};
`;
