import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export const OverlayContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    opacity: ${({ opacity }) => opacity};
    transition: opacity 0.5s;
    ${({ blurRadius }) => blurRadius ? `
        filter: blur(${blurRadius}px);
        -webkit-filter: blur(${blurRadius}px);
    ` : ''};
    ${({ resizeMode }) => resizeMode ? `
        object-fit: ${resizeMode};
    ` : ''};
`;

export const VideoWrapper = styled.div`
    opacity: ${({ opacity }) => opacity};
`;

export const Video = styled(ReactPlayer)`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
`;
