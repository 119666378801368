import './polyfills';
import './redirect';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import forEach from 'lodash/forEach';
import './index.css';
import './react-native-vector-icons';
import { App } from '@pole-journal-web/app';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Amplify } from 'aws-amplify';
import { linkStores } from '@codexporer.io/expo-link-stores';
import { init as initDevice } from '@codexporer.io/expo-device';
import {
    awsConfig,
    awsAnalyticsConfig
} from '@pole-journal/aws-amplify/src/aws.config';
import { useAppInfoActions, APP_CONTAINER } from '@codexporer.io/expo-app-info';
import { initialize as initializeRequestCountry } from '@codexporer.io/request-country';
import { initialize as initializeRequestIp } from '@codexporer.io/request-ip';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAppAnalytics, EventName } from '@pole-journal-web/app-analytics-bridge';
import { SubscriptionPlatform } from '@pole-journal/aws-amplify/src/models';
import { useRedirectToUrl } from '@codexporer.io/web-auth-screens';
import {
    configAdapter,
    useInitializeAuthentication
} from '@codexporer.io/expo-amplify-auth';
import {
    useGetLinkStores,
    initDatastoreCache,
    useFetchSubscriptionDetails,
    useSubscriptionDetails,
    useIsAppRemoteConfigInitialized,
    useFetchAppRemoteConfig,
    useMe,
    graphql,
    getOwner
} from '@pole-journal/aws-amplify/src/datastore';
import { initUserSubscriptions } from '@codexporer.io/expo-user-subscription';
import { Splash } from '@pole-journal-web/splash/src/async';
import reportWebVitals from './reportWebVitals';
import { firebaseConfig } from './firebase.config';

initUserSubscriptions({
    restApiName: awsConfig.restApi.name,
    SubscriptionPlatform,
    graphql,
    getOwner,
    useFetchSubscriptionDetails,
    useIsUserFetched: () => !!useMe().me,
    useGetSubscriptionType: () => useSubscriptionDetails()?.subscriptionType,
    extraAvailableSubscriptionFields: ['subscriptionType']
});

initializeIcons();

forEach([
    initializeRequestCountry,
    initializeRequestIp
], initialize => {
    initialize({
        saveData: (key, data) => AsyncStorage.setItem(key, data),
        loadData: key => AsyncStorage.getItem(key),
        retentionInMs: 60 * 60 * 24 * 1000 // 1 day
    });
});

initDatastoreCache({
    chache: {
        setItem: (key, value) => AsyncStorage.setItem(key, value),
        getItem: key => AsyncStorage.getItem(key),
        removeItem: key => AsyncStorage.removeItem(key)
    }
});

const useLinkStores = () => {
    linkStores({
        ...useGetLinkStores()
    });
};

const AppRoot = () => {
    useRedirectToUrl();

    const [isInitializationStarted, setIsInitializationStarted] = useState(false);
    const [
        areIndependantPrerequisitesLoaded,
        setAreIndependantPrerequisitesLoaded
    ] = useState(false);
    const [arePrerequisitesLoaded, setArePrerequisitesLoaded] = useState(false);
    const [, { initialize: initializeAppInfo }] = useAppInfoActions();
    const {
        initialize: initializeAnalytics,
        sendEvent
    } = useAppAnalytics({ firebaseConfig, awsConfig });
    const {
        isInitialized: isAuthenticationInitialized
    } = useInitializeAuthentication({
        canInitialize: arePrerequisitesLoaded,
        awsCognitoRegion: awsConfig.awsmobile.aws_cognito_region
    });
    const fetchAppRemoteConfig = useFetchAppRemoteConfig();
    const isAppRemoteConfigInitialized = useIsAppRemoteConfigInitialized();

    useLinkStores();

    // Independent prerequisites
    useEffect(() => {
        if (!isInitializationStarted) {
            setIsInitializationStarted(true);
            Promise.all([
                initDevice(),
                initializeAppInfo({ appContainer: APP_CONTAINER.web })
            ]).then(() => setAreIndependantPrerequisitesLoaded(true));
        }
    }, [
        isInitializationStarted,
        setIsInitializationStarted,
        setAreIndependantPrerequisitesLoaded,
        initializeAppInfo
    ]);

    // Analytics, and other dependent prerequisites
    useEffect(() => {
        const canLoadPrerequisites = areIndependantPrerequisitesLoaded && !arePrerequisitesLoaded;
        if (canLoadPrerequisites) {
            initializeAnalytics();
            configAdapter(awsConfig.awsmobile);
            // Must to be called after initializeAnalytics
            Amplify.configure(awsConfig.awsmobile);
            Amplify.configure({
                ...Amplify.getConfig(),
                ...awsAnalyticsConfig
            });
            setArePrerequisitesLoaded(true);
        }
    }, [
        areIndependantPrerequisitesLoaded,
        arePrerequisitesLoaded,
        initializeAnalytics,
        setArePrerequisitesLoaded
    ]);

    // Fetch remote config, must be after Amplify is configured
    useEffect(() => {
        const canFetchRemoteConfig = arePrerequisitesLoaded && !isAppRemoteConfigInitialized;
        canFetchRemoteConfig && fetchAppRemoteConfig();
    }, [
        isAppRemoteConfigInitialized,
        arePrerequisitesLoaded,
        fetchAppRemoteConfig
    ]);

    useEffect(() => {
        if (arePrerequisitesLoaded) {
            sendEvent({ name: EventName.appStart });
        }
    }, [arePrerequisitesLoaded, sendEvent]);

    const isAppLoaded = arePrerequisitesLoaded &&
        isAuthenticationInitialized &&
        isAppRemoteConfigInitialized;
    if (!isAppLoaded) {
        return <Splash />;
    }

    return <App />;
};

ReactDOM.render(
    <React.StrictMode>
        <AppRoot />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
