import {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import toLower from 'lodash/toLower';
import map from 'lodash/map';
import remove from 'lodash/remove';
import { graphql } from './graphql';

const localInitialState = {
    usersSearchData: {
        users: [],
        isLoading: false,
        error: null,
        nextToken: null,
        searchText: null
    },
    userDetailsData: {},
    userFolloweesData: {
        userFollows: [],
        isLoading: false,
        error: null,
        didInitialLoad: false
    },
    userFollowersData: {
        userFollows: [],
        isLoading: false,
        error: null,
        didInitialLoad: false
    }
};

const getUserFieldsQueryFragment = ({ shouldIncludeDetails }) => `
    fragment UserFields on User {
        id
        extraData {
            identityId
        }
        username
        profileImageId
        ${shouldIncludeDetails ? `
            profileCoverImageId
            bio
            socialLinks {
                link
                displayText
            }
            subscriptionType
        ` : ''}
        _version
        _deleted
    }
`;

const getUserByIdQuery = `
    ${getUserFieldsQueryFragment({ shouldIncludeDetails: true })}
    query GetUser($id: ID!) {
        result: getUser(id: $id) {
            ...UserFields
        }
    }
`;

const listUsersQuery = `
    ${getUserFieldsQueryFragment({ shouldIncludeDetails: false })}
    query QueryUsers(
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        result: listUsers(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...UserFields
            }
            nextToken
        }
    }
`;

export const userFollowQueryFragment = ({ shouldFetchFollower, shouldFetchFollowee }) => `
    ${getUserFieldsQueryFragment({ shouldIncludeDetails: false })}
    fragment UserFollowFields on UserFollow {
        id
        followerId
        followeeId
        ${shouldFetchFollower ? `follower {
            ...UserFields 
        }` : ''}
        ${shouldFetchFollowee ? `followee {
            ...UserFields
        }` : ''}
        _version
        _deleted
    }
`;

const getUserFollowsByFolloweeQuery = `
    ${userFollowQueryFragment({ shouldFetchFollower: true })}
    query UserFollowsByFollowee(
        $followeeId: ID!
        $limit: Int
        $nextToken: String
    ) {
        result: userFollowsByFollowee(
            followeeId: $followeeId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...UserFollowFields
            }
            nextToken
        }
    }
`;

const getUserFollowsByFollowerQuery = `
    ${userFollowQueryFragment({ shouldFetchFollowee: true })}
    query UserFollowsByFollower(
        $followerId: ID!
        $limit: Int
        $nextToken: String
    ) {
        result: userFollowsByFollower(
            followerId: $followerId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...UserFollowFields
            }
            nextToken
        }
    }
`;

const getSaveUserFollowQuery = `
    ${userFollowQueryFragment({ shouldFetchFollowee: true })}
    mutation SaveUserFollow
    (
        $followerId: ID!
        $followeeId: ID!
    ) {
        result: createUserFollow(
            input: {
                followerId: $followerId
                followeeId: $followeeId
            }
        ) {
            ...UserFollowFields
        }
    }
`;

const deleteUserFollowQuery = `
    ${userFollowQueryFragment({ shouldFetchFollowee: true })}
    mutation DeleteUserFollow
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteUserFollow(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...UserFollowFields
        }
    }
`;

/**
 * Users search
 */

const setUsersSearchData = users => ({ getState, setState }) => {
    setState({
        usersSearchData: {
            ...getState().usersSearchData,
            users
        }
    });
};

const setUsersSearchDataNextToken = nextToken => ({ getState, setState }) => {
    setState({
        usersSearchData: {
            ...getState().usersSearchData,
            nextToken
        }
    });
};

const setIsLoadingUsersSearchData = isLoading => ({ getState, setState }) => {
    setState({
        usersSearchData: {
            ...getState().usersSearchData,
            isLoading
        }
    });
};

const setUsersSearchDataError = error => ({ getState, setState }) => {
    setState({
        usersSearchData: {
            ...getState().usersSearchData,
            error
        }
    });
};

const setUsersSearchDataSearchText = searchText => ({ getState, setState }) => {
    setState({
        usersSearchData: {
            ...getState().usersSearchData,
            searchText
        }
    });
};

const LIMIT = 20;
const SEARCH_LIMIT = 500;
const fetchData = async ({
    searchText,
    nextToken,
    limit = searchText ? SEARCH_LIMIT : LIMIT,
    users = []
}) => {
    const result = await graphql({
        query: listUsersQuery,
        variables: {
            filter: searchText ? {
                username: {
                    contains: toLower(searchText)
                }
            } : undefined,
            nextToken,
            limit
        }
    });

    users.push(
        ...filter(
            result.data.result?.items,
            ({ _deleted }) => _deleted !== true
        )
    );

    nextToken = result.data.result?.nextToken;
    if (nextToken && users.length < LIMIT) {
        return fetchData({
            searchText,
            nextToken,
            limit,
            users
        });
    }

    return {
        users,
        nextToken
    };
};

const searchUsers = ({ searchText }) => async ({ getState, dispatch }) => {
    const { isLoading, searchText: searchTextCurrent } = getState().usersSearchData;
    const isSearchTextChanged = searchText !== searchTextCurrent;

    if (isSearchTextChanged) {
        dispatch(setUsersSearchDataSearchText(searchText));
    }

    if (isLoading && !isSearchTextChanged) {
        return;
    }

    try {
        dispatch(setIsLoadingUsersSearchData(true));
        dispatch(setUsersSearchDataError(null));
        dispatch(setUsersSearchData([]));

        const {
            users,
            nextToken
        } = await fetchData({ searchText, nextToken: null });

        if (getState().usersSearchData.searchText !== searchText) {
            return;
        }
        dispatch(setUsersSearchData(users));
        dispatch(setIsLoadingUsersSearchData(false));
        dispatch(setUsersSearchDataNextToken(nextToken));
    } catch (error) {
        if (getState().usersSearchData.searchText !== searchText) {
            return;
        }
        dispatch(setIsLoadingUsersSearchData(false));
        dispatch(setUsersSearchDataError(error));
    }
};

const searchMoreUsers = ({ searchText }) => async ({ getState, dispatch }) => {
    const {
        isLoading,
        nextToken: previousNextToken,
        searchText: searchTextCurrent
    } = getState().usersSearchData;

    const isSearchTextChanged = searchText !== searchTextCurrent;
    if (isSearchTextChanged) {
        dispatch(setUsersSearchDataSearchText(searchText));
    }

    if (isLoading && !isSearchTextChanged || !previousNextToken) {
        return;
    }

    try {
        dispatch(setIsLoadingUsersSearchData(true));

        const {
            users,
            nextToken
        } = await fetchData({ searchText, nextToken: previousNextToken });

        if (getState().usersSearchData.searchText !== searchText) {
            return;
        }

        dispatch(setIsLoadingUsersSearchData(false));
        dispatch(setUsersSearchData(
            reduce(
                users,
                (users, user) => {
                    !find(users, { id: user.id }) && users.push(user);
                    return users;
                },
                [...getState().usersSearchData.users]
            )
        ));
        dispatch(setUsersSearchDataNextToken(nextToken));
    } catch (error) {
        if (getState().usersSearchData.searchText !== searchText) {
            return;
        }
        dispatch(setIsLoadingUsersSearchData(false));
        dispatch(setUsersSearchDataError(error));
    }
};

/**
 * User details data
 */

const setUserDetails = user => ({ getState, setState }) => {
    setState({
        userDetailsData: {
            ...getState().userDetailsData,
            [user.id]: {
                ...(getState().userDetailsData[user.id] ?? {}),
                user
            }
        }
    });
};

const setIsLoadingUserDetails = ({
    id,
    isLoading
}) => ({ getState, setState }) => {
    setState({
        userDetailsData: {
            ...getState().userDetailsData,
            [id]: {
                ...(getState().userDetailsData[id] ?? {}),
                isLoading
            }
        }
    });
};

const setUserDetailsError = ({
    id,
    error
}) => ({ getState, setState }) => {
    setState({
        userDetailsData: {
            ...getState().userDetailsData,
            [id]: {
                ...(getState().userDetailsData[id] ?? {}),
                error
            }
        }
    });
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const fetchUser = ({ id }) => async ({ getState, dispatch }) => {
    const isLoading = getState().userDetailsData[id]?.isLoading ?? false;
    if (isLoading) {
        return;
    }

    try {
        dispatch(setIsLoadingUserDetails({ id, isLoading: true }));

        const user = await graphql({
            query: getUserByIdQuery,
            variables: { id }
        });

        dispatch(setIsLoadingUserDetails({ id, isLoading: false }));
        dispatch(setUserDetails(user.data.result));
    } catch (error) {
        dispatch(setIsLoadingUserDetails({ id, isLoading: false }));
        dispatch(setUserDetailsError({ id, error }));
    }
};

/**
 * User followers
 */

const setUserFollowersData = userFollows => ({ getState, setState }) => {
    setState({
        userFollowersData: {
            ...getState().userFollowersData,
            userFollows
        }
    });
};

const setIsUserFollowersLoading = isLoading => ({ getState, setState }) => {
    setState({
        userFollowersData: {
            ...getState().userFollowersData,
            isLoading
        }
    });
};

const setUserFollowersError = error => ({ getState, setState }) => {
    setState({
        userFollowersData: {
            ...getState().userFollowersData,
            error
        }
    });
};

const onUserFollowersInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState().userFollowersData;
    !didInitialLoad && setState({
        userFollowersData: {
            ...getState().userFollowersData,
            didInitialLoad: true
        }
    });
};

const fetchFollowers = ({ followeeId }) => async ({ getState, dispatch }) => {
    const { isLoading } = getState().userFollowersData;
    if (isLoading) {
        return;
    }

    const followers = [];
    const fetchFollowers = async ({ nextToken, limit }) => {
        const result = await graphql({
            query: getUserFollowsByFolloweeQuery,
            variables: {
                followeeId,
                limit,
                nextToken
            }
        });

        followers.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchFollowers({ nextToken });
        }
    };

    try {
        dispatch(setIsUserFollowersLoading(true));
        dispatch(setUserFollowersError(null));
        dispatch(setUserFollowersData([]));

        await fetchFollowers({ nextToken: null });

        dispatch(setUserFollowersData(followers));
        dispatch(setIsUserFollowersLoading(false));
    } catch (error) {
        dispatch(setIsUserFollowersLoading(false));
        dispatch(setUserFollowersError(error));
    }
};

/**
 * User followees
 */

const setUserFolloweesData = userFollows => ({ getState, setState }) => {
    setState({
        userFolloweesData: {
            ...getState().userFolloweesData,
            userFollows
        }
    });
};

const setIsUserFolloweesLoading = isLoading => ({ getState, setState }) => {
    setState({
        userFolloweesData: {
            ...getState().userFolloweesData,
            isLoading
        }
    });
};

const setUserFolloweesError = error => ({ getState, setState }) => {
    setState({
        userFolloweesData: {
            ...getState().userFolloweesData,
            error
        }
    });
};

const onUserFolloweesInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState().userFolloweesData;
    !didInitialLoad && setState({
        userFolloweesData: {
            ...getState().userFolloweesData,
            didInitialLoad: true
        }
    });
};

const fetchFollowees = ({ followerId }) => async ({ getState, dispatch }) => {
    const { isLoading } = getState().userFolloweesData;
    if (isLoading) {
        return;
    }

    const followees = [];
    const fetchFollowees = async ({ nextToken, limit }) => {
        const result = await graphql({
            query: getUserFollowsByFollowerQuery,
            variables: {
                followerId,
                limit,
                nextToken
            }
        });

        followees.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchFollowees({ nextToken });
        }
    };

    try {
        dispatch(setIsUserFolloweesLoading(true));
        dispatch(setUserFolloweesError(null));
        dispatch(setUserFolloweesData([]));

        await fetchFollowees({ nextToken: null });

        dispatch(setUserFolloweesData(followees));
        dispatch(setIsUserFolloweesLoading(false));
    } catch (error) {
        dispatch(setIsUserFolloweesLoading(false));
        dispatch(setUserFolloweesError(error));
    }
};

/**
 * User follow
 */

const saveUserFollow = ({ userFollow }) => async ({ getState, dispatch }) => {
    const savedUserFollow = (await graphql({
        query: getSaveUserFollowQuery,
        variables: userFollow
    })).data.result;

    const userFollows = [...getState().userFolloweesData.userFollows];
    userFollows.push(savedUserFollow);
    dispatch(setUserFolloweesData(userFollows));
    return savedUserFollow;
};

const deleteUserFollow = ({
    userFollow: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedUserFollow = (await graphql({
        query: deleteUserFollowQuery,
        variables: { id, _version }
    })).data.result;

    const userFollows = [...getState().userFolloweesData.userFollows];
    remove(userFollows, ({ id }) => id === deletedUserFollow.id);
    dispatch(setUserFolloweesData(userFollows));
    return deletedUserFollow;
};

/**
 * Store and hooks
 */

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchUser,
        searchUsers,
        searchMoreUsers,
        saveUserFollow,
        deleteUserFollow,
        fetchFollowers,
        onUserFollowersInitialLoad,
        fetchFollowees,
        onUserFolloweesInitialLoad
    },
    name: 'Users'
});

const useUsersSearchDataState = createStateHook(
    Store,
    {
        selector: ({
            usersSearchData: {
                searchText,
                users,
                isLoading,
                error,
                nextToken
            }
        }) => ({
            searchText,
            users,
            isLoading: isLoading ?? true,
            error,
            nextToken
        })
    }
);

const useUserState = createStateHook(
    Store,
    {
        selector: ({ userDetailsData }, { id }) => {
            const error = userDetailsData[id]?.error ?? null;
            return {
                user: !error ? (userDetailsData[id]?.user ?? null) : null,
                isLoading: userDetailsData[id]?.isLoading ?? true,
                error
            };
        }
    }
);

const useFolloweesDataState = createStateHook(
    Store,
    {
        selector: ({
            userFolloweesData: {
                userFollows,
                isLoading,
                error,
                didInitialLoad
            }
        }) => ({
            userFollows,
            isLoading: isLoading ?? true,
            error,
            didInitialLoad
        })
    }
);

const useFollowersDataState = createStateHook(
    Store,
    {
        selector: ({
            userFollowersData: {
                userFollows,
                isLoading,
                error,
                didInitialLoad
            }
        }) => ({
            userFollows,
            isLoading: isLoading ?? true,
            error,
            didInitialLoad
        })
    }
);

export const useUsersActions = createActionsHook(Store);

export const useUsersSearchData = () => {
    const {
        users,
        isLoading,
        error,
        nextToken
    } = useUsersSearchDataState();

    return {
        users,
        isLoading,
        error,
        canLoadMore: !!nextToken
    };
};

export const useFollowersData = ({ followeeId }) => {
    const { fetchFollowers, onUserFollowersInitialLoad } = useUsersActions();
    const {
        userFollows,
        isLoading,
        error,
        didInitialLoad
    } = useFollowersDataState();

    useEffect(() => {
        if (!didInitialLoad) {
            fetchFollowers({ followeeId });
            onUserFollowersInitialLoad();
        }
    }, [didInitialLoad, fetchFollowers, followeeId, onUserFollowersInitialLoad]);

    return {
        userFollows,
        isLoading,
        error
    };
};

export const useFolloweesData = ({ followerId }) => {
    const { fetchFollowees, onUserFolloweesInitialLoad } = useUsersActions();
    const {
        userFollows,
        isLoading,
        error,
        didInitialLoad
    } = useFolloweesDataState();

    useEffect(() => {
        if (!didInitialLoad) {
            fetchFollowees({ followerId });
            onUserFolloweesInitialLoad();
        }
    }, [didInitialLoad, fetchFollowees, followerId, onUserFolloweesInitialLoad]);

    return {
        userFollows,
        isLoading,
        error
    };
};

export const useFriends = ({ userId }) => {
    const followersData = useFollowersData({ followeeId: userId });
    const followeesData = useFolloweesData({ followerId: userId });

    const userFollowsFollowers = followersData.userFollows;
    const userFollowsFollowees = followeesData.userFollows;

    return {
        friends: useMemo(() => {
            const followers = map(userFollowsFollowers, 'follower');
            const followees = map(userFollowsFollowees, 'followee');

            return reduce(followers, (friends, follower) => {
                if (find(followees, { id: follower.id })) {
                    friends.push(follower);
                }
                return friends;
            }, []);
        }, [userFollowsFollowers, userFollowsFollowees]),
        isLoading: followersData.isLoading || followeesData.isLoading,
        error: followersData.error || followeesData.error
    };
};

export const useSearchUsers =
    () => useUsersActions().searchUsers;

export const useSearchMoreUsers =
    () => useUsersActions().searchMoreUsers;

export const useUser = ({
    id,
    shouldFetchOnMount = true
}) => {
    const { fetchUser } = useUsersActions();
    const {
        user,
        isLoading,
        error
    } = useUserState({ id });
    const [didFetch, setDidFetch] = useState(false);

    useEffect(() => {
        if (id && shouldFetchOnMount && !didFetch) {
            setDidFetch(true);
            fetchUser({ id });
        }
    }, [
        shouldFetchOnMount,
        didFetch,
        fetchUser,
        id
    ]);

    return id ? {
        user,
        isLoading,
        error
    } : {
        user: null,
        isLoading: false,
        error: null
    };
};

export const useFetchFollowers =
    () => useUsersActions().fetchFollowers;

export const useFetchFollowees =
    () => useUsersActions().fetchFollowees;

export const useFetchFriends = () => {
    const fetchFollowers = useFetchFollowers();
    const fetchFollowees = useFetchFollowees();

    return useCallback(({ userId }) => {
        fetchFollowers({ followeeId: userId });
        fetchFollowees({ followerId: userId });
    }, [fetchFollowers, fetchFollowees]);
};

export const useSaveUserFollow =
    () => useUsersActions().saveUserFollow;

export const useDeleteUserFollow =
    () => useUsersActions().deleteUserFollow;

export const useUserIdentityId = ({ id }) => useUser({
    id,
    shouldFetchOnMount: false
}).user?.extraData.identityId;

export const useFetchUser = () => useUsersActions().fetchUser;
