import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import {
    uniqueNamesGenerator,
    colors,
    animals
} from 'unique-names-generator';
import find from 'lodash/find';
import random from 'lodash/random';
import truncate from 'lodash/truncate';
import trim from 'lodash/trim';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
    getOwner,
    getCognitoUsername
} from './user-utils';
import { graphql } from './graphql';
import { awsConfig } from '../aws.config';
import {
    saveFile,
    StoreType,
    StorageAccessLevel
} from '../storage';

const localInitialState = {
    user: null,
    subscriptionDetails: null,
    isLoading: null,
    error: null
};

const userFieldsQueryFragment = `
    fragment UserFields on User {
        id
        extraDataId
        username
        profileImageId
        profileCoverImageId
        isTrackingMoveStatus
        bio
        socialLinks {
            link
            displayText
        }
        owner
        createdAt
        updatedAt
        _lastChangedAt
        _version
        _deleted
    }
`;

const userExtraDataFieldsQueryFragment = `
    fragment UserExtraDataFields on UserExtraData {
        id
        owner
        cognitoUsername
        identityId
        onboardingConfig
        lastActivity
        createdAt
        updatedAt
        _lastChangedAt
        _version
        _deleted
    }
`;

const getUsersByOwnerQuery = `
    ${userFieldsQueryFragment}
    ${userExtraDataFieldsQueryFragment}
    query QueryUsersByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: usersByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...UserFields
                extraData {
                   ...UserExtraDataFields 
                }
            }
            nextToken
        }
    }
`;

const getUserExtraDataByOwnerQuery = `
    ${userExtraDataFieldsQueryFragment}
    query QueryUserExtraDataByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: userExtraDataByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...UserExtraDataFields
            }
            nextToken
        }
    }
`;

const getUsersByUsernameQuery = `
    query QueryUsersByUsername(
        $username: String!
        $limit: Int
        $nextToken: String
    ) {
        result: usersByUsername(
            username: $username
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
        }
    }
`;

const getSaveUserQuery = ({ isUpdate }) => `
    ${userFieldsQueryFragment}
    ${userExtraDataFieldsQueryFragment}
    mutation SaveUser
    (
        $extraDataId: ID!
        $username: String!
        $profileImageId: String
        $profileCoverImageId: String
        $isTrackingMoveStatus: Boolean
        $bio: String
        $socialLinks: [LinkInput!]
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateUser' : 'createUser'}(
            input: {
                extraDataId: $extraDataId
                username: $username
                profileImageId: $profileImageId
                profileCoverImageId: $profileCoverImageId
                isTrackingMoveStatus: $isTrackingMoveStatus
                bio: $bio
                socialLinks: $socialLinks
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...UserFields
            extraData {
                ...UserExtraDataFields 
            }
        }
    }
`;

const getSaveUserExtraDataQuery = ({ isUpdate }) => `
    ${userExtraDataFieldsQueryFragment}
    mutation SaveUserExtraData
    (
        $cognitoUsername: String!
        $identityId: String!
        $lastActivity: String!
        $onboardingConfig: AWSJSON
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateUserExtraData' : 'createUserExtraData'}(
            input: {
                cognitoUsername: $cognitoUsername
                identityId: $identityId
                lastActivity: $lastActivity
                onboardingConfig: $onboardingConfig
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...UserExtraDataFields
        }
    }
`;

const deleteUserQuery = `
    ${userFieldsQueryFragment}
    mutation DeleteUser
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteUser(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...UserFields
        }
    }
`;

const generateUsername = () => {
    const generatedName = uniqueNamesGenerator({
        dictionaries: [colors, animals],
        length: 2,
        separator: '.',
        style: 'lowerCase'
    });

    const trimmedGeneratedName = trim(
        truncate(
            generatedName,
            {
                length: 20,
                omission: ''
            }
        ),
        '.'
    );

    return `${trimmedGeneratedName}${random(1, 999)}`;
};

export const USERNAME_MIN_LENGTH = 1;
export const USERNAME_MAX_LENGTH = 30;
export const USERNAME_VALIDATION_STATUS = {
    success: 0,
    invalidFormat: 1,
    invalidLength: 2,
    alreadyExists: 3
};

export const validateUsername = async ({
    username,
    shouldVerifyDuplicate = true
}) => {
    const isCorrectFormat = /^([a-z0-9._]+)$/.test(username);
    if (!isCorrectFormat) {
        return USERNAME_VALIDATION_STATUS.invalidFormat;
    }

    if (username.length < USERNAME_MIN_LENGTH || username.length > USERNAME_MAX_LENGTH) {
        return USERNAME_VALIDATION_STATUS.invalidLength;
    }

    if (!shouldVerifyDuplicate) {
        return USERNAME_VALIDATION_STATUS.success;
    }

    const result = await graphql({
        query: getUsersByUsernameQuery,
        variables: {
            username,
            limit: 1,
            nextToken: null
        }
    });

    if (result.data.result?.items?.length > 0) {
        return USERNAME_VALIDATION_STATUS.alreadyExists;
    }

    return USERNAME_VALIDATION_STATUS.success;
};

const setUser = user => ({ setState }) => {
    setState({ user });
};

const setSubscriptionDetails = subscriptionDetails => ({ setState }) => {
    setState({ subscriptionDetails });
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchItem = async ({
    owner,
    nextToken,
    query
}) => {
    const result = await graphql({
        query,
        variables: {
            owner,
            limit: 100,
            nextToken
        }
    });

    const item = find(
        result.data.result?.items,
        ({ _deleted }) => _deleted !== true
    );

    if (item) {
        return item;
    }

    nextToken = result.data.result?.nextToken;
    if (nextToken) {
        return fetchItem({
            owner,
            nextToken,
            query
        });
    }

    return null;
};

const fetchUserSubscriptionDetails = async ({ owner }) => {
    const retrieveSubscriptionRestOperation = post({
        apiName: awsConfig.restApi.name,
        path: '/user-subscription/retrieve-subscription',
        options: {
            body: {
                owner,
                shouldIgnoreRecentCheck: false
            }
        }
    });
    const { body } = await retrieveSubscriptionRestOperation.response;
    const {
        isSubscribed,
        details
    } = await body.json();
    return {
        isSubscribed,
        details
    };
};

const fetchMe = () => async ({ getState, dispatch }) => {
    const { isLoading } = getState();
    if (isLoading) {
        return;
    }

    try {
        dispatch(setError(null));
        dispatch(setIsLoading(true));

        const { owner } = await getOwner();

        // Handle user extra data
        let userExtraData = await fetchItem({
            owner,
            nextToken: null,
            query: getUserExtraDataByOwnerQuery
        });
        const hasExtraData = !!userExtraData;

        if (!hasExtraData) {
            const { identityId } = await fetchAuthSession();
            const cognitoUsername = await getCognitoUsername();
            userExtraData = (await graphql({
                query: getSaveUserExtraDataQuery({ isUpdate: false }),
                variables: {
                    cognitoUsername,
                    identityId,
                    lastActivity: new Date().toISOString()
                }
            })).data.result;
        }

        // Handle user
        let user = await fetchItem({
            owner,
            nextToken: null,
            query: getUsersByOwnerQuery
        });
        if (!user) {
            let username;
            for (let trial = 0; trial < 10; trial += 1) {
                username = generateUsername();
                // eslint-disable-next-line no-await-in-loop
                const validationStatus = await validateUsername({ username });
                if (validationStatus === USERNAME_VALIDATION_STATUS.success) {
                    break;
                }

                username = null;
            }

            if (!username) {
                throw new Error("Couldn't generate username.");
            }

            user = (await graphql({
                query: getSaveUserQuery({ isUpdate: false }),
                variables: {
                    username,
                    extraDataId: userExtraData.id,
                    isTrackingMoveStatus: true
                }
            })).data.result;
        }

        const {
            isSubscribed,
            details
        } = await fetchUserSubscriptionDetails({ owner });

        dispatch(setSubscriptionDetails(isSubscribed ? details : null));
        dispatch(setUser({
            ...user,
            isTrackingMoveStatus: user.isTrackingMoveStatus ?? true,
            socialLinks: user.socialLinks ?? []
        }));
        dispatch(setIsLoading(false));

        // If user extra data was loaded initially, handle update in the background
        if (hasExtraData) {
            (async () => {
                try {
                    const { lastActivity: previousLastActivityStr } = userExtraData;
                    const previousLastActivity = new Date(previousLastActivityStr);
                    const lastActivity = new Date();
                    const diffTime = Math.abs(lastActivity - previousLastActivity);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const isLastActiveInMoreThanADay = diffDays > 1;

                    if (isLastActiveInMoreThanADay) {
                        userExtraData = (await graphql({
                            query: getSaveUserExtraDataQuery({ isUpdate: true }),
                            variables: {
                                ...userExtraData,
                                lastActivity: new Date().toISOString()
                            }
                        })).data.result;

                        dispatch(setUser({
                            ...getState().user,
                            extraData: userExtraData
                        }));
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            })();
        }
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const fetchSubscriptionDetails = () => async ({ dispatch }) => {
    try {
        const { owner } = await getOwner();
        const {
            isSubscribed,
            details
        } = await fetchUserSubscriptionDetails({ owner });
        dispatch(setSubscriptionDetails(isSubscribed ? details : null));
    } catch {
        // ignore empty block
    }
};

const saveMe = ({
    me,
    mePreviousState,
    profileImageUrl,
    profileCoverImageUrl
}) => async ({ dispatch }) => {
    const savedMe = (await graphql({
        query: getSaveUserQuery({ isUpdate: true }),
        variables: { ...me }
    })).data.result;

    const {
        profileImageId,
        profileCoverImageId
    } = me;
    const previousProfileImageId = mePreviousState?.profileImageId;
    const previousProfileCoverImageId = mePreviousState?.profileCoverImageId;

    await Promise.all([
        !!profileImageId && previousProfileImageId !== profileImageId ?
            saveFile({
                id: profileImageId,
                url: profileImageUrl,
                accessLevel: StorageAccessLevel.protected,
                storeType: StoreType.userProfileImage
            }) :
            Promise.resolve(),
        !!profileCoverImageId && previousProfileCoverImageId !== profileCoverImageId ?
            saveFile({
                id: profileCoverImageId,
                url: profileCoverImageUrl,
                accessLevel: StorageAccessLevel.protected,
                storeType: StoreType.userProfileCoverImage
            }) :
            Promise.resolve()
    ]);

    dispatch(setUser(savedMe));
    return savedMe;
};

const saveMeExtraData = ({
    extraData
}) => async ({ dispatch, getState }) => {
    const savedExtraData = (await graphql({
        query: getSaveUserExtraDataQuery({ isUpdate: true }),
        variables: { ...extraData }
    })).data.result;

    const { user: me } = getState();
    dispatch(setUser({
        ...me,
        extraData: savedExtraData
    }));
    return savedExtraData;
};

const deleteMe = () => async ({ dispatch }) => {
    const { owner } = await getOwner();
    const { id, _version } = await fetchItem({
        owner,
        nextToken: null,
        query: getUsersByOwnerQuery
    });
    const deletedUser = (await graphql({
        query: deleteUserQuery,
        variables: { id, _version }
    })).data.result;
    dispatch(setUser(null));
    return deletedUser;
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchMe,
        fetchSubscriptionDetails,
        saveMe,
        saveMeExtraData,
        deleteMe
    },
    name: 'Me'
});

const useMeState = createStateHook(
    Store,
    {
        selector: (
            {
                user,
                isLoading,
                error
            }
        ) => ({
            me: user,
            isLoading: isLoading || isLoading === null,
            error
        })
    }
);

export const useSubscriptionDetails = createStateHook(
    Store,
    {
        selector: ({ subscriptionDetails }) => subscriptionDetails
    }
);

export const useMeActions = createActionsHook(Store);

export const useMe = () => {
    const {
        me,
        isLoading,
        error
    } = useMeState();

    return {
        me,
        isLoading,
        error
    };
};

export const useMeExtraData = () => {
    const { me } = useMeState();

    return me?.extraData;
};

export const useIdentityId = () => {
    const { me } = useMeState();
    return me?.extraData?.identityId;
};

export const useFetchMe = () => {
    const { fetchMe } = useMeActions();
    return fetchMe;
};

export const useSaveMe = () => {
    const { saveMe } = useMeActions();
    return saveMe;
};

export const useSaveMeExtraData = () => {
    const { saveMeExtraData } = useMeActions();
    return saveMeExtraData;
};

export const useDeleteMe = () => {
    const { deleteMe } = useMeActions();
    return deleteMe;
};

export const useFetchSubscriptionDetails = () => {
    const { fetchSubscriptionDetails } = useMeActions();
    return fetchSubscriptionDetails;
};
