import React from 'react';
import { di } from 'react-magnetic-di';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@pole-journal-web/app-screen-skeleton';

const AsyncOnlineLibraryMoves = lazyForPaint(
    () => import(/* webpackChunkName: "async-online-library-moves" */ './index')
        .then(({ OnlineLibraryMoves }) => OnlineLibraryMoves)
);

export const OnlineLibraryMoves = () => {
    di(AsyncOnlineLibraryMoves, LazySuspense, Skeleton);

    return (
        <LazySuspense fallback={<Skeleton />}>
            <AsyncOnlineLibraryMoves />
        </LazySuspense>
    );
};
