import includes from 'lodash/includes';
import find from 'lodash/find';

const shouldRedirect = find(
    [
        'pole-journal',
        'acrobacy'
    ],
    redirectFrom => includes(window.location.host, redirectFrom)
);
if (shouldRedirect) {
    const url = new URL(window.location.toString());
    url.hostname = 'acro.fitness';
    // eslint-disable-next-line lodash/prefer-lodash-method
    window.location.replace(url.toString());
}
